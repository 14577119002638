import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../../store'

interface AuthGuardProps {
  children: JSX.Element
}

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const token = useUserContext((state) => state.token)

  if (token) {
    return children
  }

  return <Navigate to="/login" replace />
}
