import { useConfirmStore } from '../../store'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export const ConfirmDialog = () => {
  const { open, config, resolveCallback, closeConfirm } = useConfirmStore()

  const onConfirm = () => {
    resolveCallback && resolveCallback(true)
    closeConfirm()
  }

  const onReject = () => {
    resolveCallback && resolveCallback(false)
    closeConfirm()
  }

  return (
    <Dialog open={open}>
      {config.promptTitle && <DialogTitle sx={{ textTransform: 'uppercase' }}>{config.promptTitle}</DialogTitle>}
      {config.promptMessage && (
        <DialogContent>
          <DialogContentText>{config.promptMessage}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onConfirm} variant="contained" color={config.confirmButtonColor}>
          {config.confirmButtonLabel}
        </Button>
        <Button onClick={onReject} variant="outlined">
          {config.rejectButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
