import { useMediaQuery, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { VIEWPORT_MD } from '../../constants'
import { FILTER_BUTTON_STYLE } from '../../style/sx'
import { StringObject } from '../../interfaces'

interface ToggleButtonProps<T> {
  disabled: boolean
  value: T[keyof T]
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: T[keyof T]) => void
  filterEnum: T
}

export const FilterToggleButtons = <T extends StringObject>({
  onChange,
  disabled,
  value,
  filterEnum,
}: ToggleButtonProps<T>) => {
  const matches = useMediaQuery(VIEWPORT_MD)
  const width = matches ? '22%' : '50%'

  return (
    <ToggleButtonGroup exclusive value={value} onChange={onChange} sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
      <ToggleButton value={filterEnum.Unexpired} sx={[FILTER_BUTTON_STYLE, { width, mr: 1 }]} disabled={disabled}>
        Aktuālie
      </ToggleButton>
      <ToggleButton value={filterEnum.Expired} sx={[FILTER_BUTTON_STYLE, { width, mr: 1 }]} disabled={disabled}>
        Noslēgušies
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
