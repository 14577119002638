import { writeFile, utils, writeXLSX } from 'xlsx'
import { SnackbarConfig } from '../interfaces'
import { GENERIC_ERROR } from '../constants'
import { Filesystem, Directory } from '@capacitor/filesystem'

const createWorkbook = (data: string[][], maxWidth: number, columnTitles: string[]) => {
  const workbook = utils.book_new()
  const worksheet = utils.json_to_sheet(data)
  utils.sheet_add_aoa(worksheet, [columnTitles], { origin: 'A1' })
  utils.book_append_sheet(workbook, worksheet, 'Atskaite')
  worksheet['!cols'] = [{ wch: maxWidth }]
  return workbook
}

export const xlsxExporter = (
  data: string[][],
  fileName: string,
  maxWidth: number,
  columnTitles: string[],
  setDownloading: (downloading: boolean) => void,
  showSnackbar: (msg: string, config?: Partial<SnackbarConfig>) => void
) => {
  const workbook = createWorkbook(data, maxWidth, columnTitles)
  const filePath = `${fileName}xlsx`
  setDownloading(true)
  try {
    writeFile(workbook, filePath)
    showSnackbar('Atskaite saglabāta!')
    setDownloading(false)
  } catch (error) {
    showSnackbar(GENERIC_ERROR, { severity: 'error' })
    setDownloading(false)
  }
}

export const xlsxMobileExporter = async (
  exportData: string[][],
  fileName: string,
  maxWidth: number,
  columnTitles: string[],
  setDownloading: (downloading: boolean) => void,
  showSnackbar: (msg: string, config?: Partial<SnackbarConfig>) => void
) => {
  const workbook = createWorkbook(exportData, maxWidth, columnTitles)
  const path = `${fileName}xlsx`
  const data = writeXLSX(workbook, { type: 'base64' })
  setDownloading(true)
  try {
    await Filesystem.writeFile({
      data,
      path,
      directory: Directory.Documents,
    })
    showSnackbar('Atskaite saglabāta!')
    setDownloading(false)
  } catch (error) {
    showSnackbar(GENERIC_ERROR, { severity: 'error' })
    setDownloading(false)
  }
}
