import { ThemeOptions } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary']
  }

  interface PaletteOptions {
    accent: PaletteOptions['primary']
  }
}

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#B455A0',
      light: '#f8eef6',
      dark: '#58595B',
    },
    secondary: {
      main: '#F89C1B',
      dark: '#414042',
    },
    accent: {
      main: '#FFFFFF',
    },
    info: {
      main: '#B455A0',
    },
  },
  typography: {
    fontFamily: '"Fira Sans", sans-serif',
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.1rem',
      color: 'inherit',
    },
    body2: {
      color: '#7F7F7F',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8888px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: '#B455A0',
          textTransform: 'none',
          backgroundColor: '#f8eef6',
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#f8eef6',
            backgroundColor: '#B455A0',
          },
        },
      },
    },
  },
}
