import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment } from '@mui/material'
import { PasswordInputType } from '../../interfaces'

interface ToggleProps {
  onShowPassword: () => void
  type: PasswordInputType
}

export const TogglePasswordVisibility = ({ onShowPassword, type }: ToggleProps) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={() => onShowPassword()} onMouseDown={() => onShowPassword()}>
        {type === 'text' ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment>
  )
}
