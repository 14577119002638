import { Box } from '@mui/material'
import { DelayedLoader } from '../DelayedLoader'

export const FullPageLoader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%)',
        textAlign: 'center',
      }}
    >
      <DelayedLoader />
    </Box>
  )
}
