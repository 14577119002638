import { FilterState } from '../interfaces'

export const updateSearchParams = (filters: FilterState) => {
  const params = {
    start: filters.startDate?.toISOString(),
    end: filters.endDate?.toISOString(),
    munId: filters.municipalityId,
  }

  const url = new URL(window.location.href)

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value) {
      url.searchParams.set(key, value)
    } else {
      url.searchParams.delete(key)
    }
  })

  window.history.replaceState({}, '', url)
}
