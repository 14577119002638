import { useState } from 'react'
import { Box, Drawer } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Header, SecondHeader, Menu, SafeAreaWrap, PageWrap } from '../../components'

export interface LayoutProps {
  children?: JSX.Element | JSX.Element[]
}

const excludedPaths = new Set([
  '/',
  '/profile',
  '/result/success/offers',
  '/result/success/offer',
  '/result/error',
  '/result/success/utilized',
])

export const Layout = ({ children }: LayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const height = excludedPaths.has(location.pathname) ? '100%' : 'calc(100% - 72px)'

  const onMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const onScanner = () => {
    navigate('/scanner')
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <Box sx={{ height: '100%' }}>
      {excludedPaths.has(location.pathname) ? (
        <SecondHeader onMenuToggle={onMenuToggle} />
      ) : (
        <Header onMenuToggle={onMenuToggle} />
      )}
      <Drawer anchor="right" open={isMenuOpen} onClose={onMenuToggle}>
        <SafeAreaWrap sx={{ height: '100%' }}>
          <Menu onClose={onMenuToggle} onScanner={onScanner} />
        </SafeAreaWrap>
      </Drawer>
      <Box
        sx={{
          paddingRight: 'env(safe-area-inset-right, 0)',
          paddingBottom: 'env(safe-area-inset-bottom, 0)',
          paddingLeft: 'env(safe-area-inset-left, 0)',
          height,
          margin: '0 auto',
        }}
      >
        <PageWrap useMinHeight={false}>{children || <Outlet />}</PageWrap>
      </Box>
    </Box>
  )
}
