import React from 'react'
import { Outlet, useRoutes, RouteObject } from 'react-router-dom'
import { UsersList } from './UsersList'
import { UserForm } from './UserForm'

const UsersLayout = () => {
  return <Outlet />
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <UsersLayout />,
    children: [
      {
        index: true,
        element: <UsersList />,
      },
      {
        path: 'new',
        element: <UserForm />,
      },
      {
        path: 'edit/:id',
        element: <UserForm />,
      },
      {
        path: '*',
        element: <UsersList />,
      },
    ],
  },
]

export const Users = () => {
  const element = useRoutes(routes)

  return <>{element}</>
}
