import { useChangePasswordMutation } from '../../graphql'

export const useChangePassword = () => {
  const [changePassword, { loading, error }] = useChangePasswordMutation()

  return {
    changePassword: (oldPassword: string, newPassword: string) => {
      return changePassword({
        variables: {
          input: {
            oldPassword,
            newPassword,
          },
        },
      }).then(({ data }) => {
        return data?.usernameAuthenticator.changePassword.token
      })
    },
    loading,
    error,
  }
}
