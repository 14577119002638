import { useState } from 'react'
import { useUserContext } from '../../store'
import { CameraFile } from '../../interfaces'
import { GRAPHQL_API_FILE_URL } from '../../constants'

export const useUploadCameraFiles = () => {
  const token = useUserContext((state) => state.token)

  const [uploadedIds, setUploadedIds] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const uploadCameraFiles = async (files: CameraFile[]) => {
    setUploadedIds([])
    setLoading(true)
    setError(false)

    const request = files.map(async (file) => {
      const formData = new FormData()
      const blob = await fetch(file.webPath).then((response) => response.blob())

      formData.append('file', blob)

      return fetch(GRAPHQL_API_FILE_URL, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: token ? `Token ${token}` : '',
        },
      }).then((response) => response.json())
    })

    return Promise.all(request)
      .then((response) => {
        const uploadIds: string[] = response.map((entry) => entry.id)

        setUploadedIds(uploadIds)
        setLoading(false)
        return uploadIds
      })
      .catch(() => setError(true))
  }
  return {
    uploadCameraFiles,
    uploadedIds,
    loading,
    error,
  }
}
