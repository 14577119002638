import { ScanQrQuery, UtilizedOfferOutputLyl } from '../../graphql'
import { QrCodeOwnerData } from '../../interfaces'

export const mapQrCodeOwnerData = (data: ScanQrQuery): QrCodeOwnerData | null => {
  try {
    const ownerData = data.loyalty.scanQrV2
    return {
      qualifiedOffers: ownerData.qualifiedOffers as UtilizedOfferOutputLyl[],
      qrCodeOwnerData: {
        firstName: ownerData.owner.strongAuthenticationData?.firstName || '',
        lastName: ownerData.owner.strongAuthenticationData?.lastName || '',
        userId: ownerData.owner.igpData?.userId,
      },
    }
  } catch {
    return null
  }
}
