import { useState, useEffect } from 'react'

export const useDetectKeyboard = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  useEffect(() => {
    const listener = () => {
      setIsKeyboardVisible(window.screen.height - 300 > window.visualViewport.height)
    }

    window.visualViewport.addEventListener('resize', listener)

    return () => {
      window.visualViewport.removeEventListener('resize', listener)
    }
  }, [])

  return isKeyboardVisible
}
