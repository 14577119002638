import { AttachmentItem } from '../interfaces'
import { AttachmentOutputLyl, AttachmentOutputPev } from '../graphql'

export const mapAttachments = (attachments: (AttachmentOutputLyl | AttachmentOutputPev)[]): AttachmentItem[] => {
  if (attachments && Array.isArray(attachments)) {
    return attachments.map((attachment) => ({
      src: `${process.env.REACT_APP_API_BASE_URL}/rest/file?id=${attachment.fileId}`,
      id: attachment.fileId,
      name: attachment.name,
    }))
  }

  return []
}
