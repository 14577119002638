import { FormEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUploadCameraFiles, useUploadGenericFiles } from '../../api'
import { EventForm, FullPageLoader } from '../../components'
import { GENERIC_ERROR } from '../../constants'
import {
  TagOutputPev,
  useAddEventMutation,
  useGetEventTagsQuery,
  useGetMunicipalitiesQuery,
  useGetMunicipalityIdLazyQuery,
} from '../../graphql'
import { AttachmentItem, CameraFile, EventEntry, Municipality, StringObject, Tag, TextEditorRef } from '../../interfaces'
import { useSnackbarStore } from '../../store'
import { mapTags, remapFileList, validateEventForm } from '../../utils'
import { mapMunicipalities } from '../../utils/municipalityUtils'

export const EventItemAdd = () => {
  const navigate = useNavigate()

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  const { uploadCameraFiles } = useUploadCameraFiles()
  const { uploadGenericFiles } = useUploadGenericFiles()
  const { data, loading: loadingMunicipalities } = useGetMunicipalitiesQuery()
  const [getMunicipalityId] = useGetMunicipalityIdLazyQuery()

  const [addEvent] = useAddEventMutation()

  const editorRef = useRef<TextEditorRef>(null)

  const [eventItem, setEventItem] = useState<EventEntry>({
    id: '',
    parentId: '',
    title: '',
    content: '',
    address: '',
    startsAt: null,
    publishedAt: null,
    canceledAt: '',
    endsAt: null,
    images: [],
    comments: [],
    hasRepliesEnabled: true,
    categoryTags: [],
    locationTags: [],
    municipalityId: '',
    going: 0,
    notGoing: 0,
    maybe: 0,
  })

  const [formErrors, setFormErrors] = useState<StringObject>({})
  const [fileList, setFileList] = useState<(File | CameraFile | AttachmentItem)[]>([])
  const [images, setImages] = useState<AttachmentItem[]>([])
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])
  const [saving, setSaving] = useState(false)
  const [locationTags, setLocationTags] = useState<Tag[]>([])
  const [categoryTags, setCategoryTags] = useState<Tag[]>([])
  const { loading: loadingTags, data: tagsData } = useGetEventTagsQuery({
    skip: eventItem.municipalityId === '',
    variables: { municipalityId: eventItem.municipalityId },
  })

  useEffect(() => {
    if (tagsData && tagsData.publicEvents && tagsData.publicEvents.tags) {
      const [type, location] = mapTags(tagsData.publicEvents.tags as TagOutputPev[], 'public-events')

      setCategoryTags(type)
      setLocationTags(location)
    }
  }, [tagsData])

  useEffect(() => {
    if (data) {
      setMunicipalities(mapMunicipalities(data))
    }
  }, [data])

  useEffect(() => {
    setImages(remapFileList(fileList))
  }, [fileList])

  const onChange = (event: EventEntry, errors: StringObject) => {
    setEventItem(event)
    setFormErrors(errors)
  }

  const errorHandler = () => {
    setSaving(false)
    showSnackbar(GENERIC_ERROR, { severity: 'error' })
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    const [isInvalid, errors, startsAt, duration] = validateEventForm(eventItem)

    setFormErrors(errors)

    if (isInvalid) {
      return
    }

    const cameraFiles: CameraFile[] = []
    const webFiles: File[] = []

    for (const file of fileList) {
      if (file instanceof File) {
        webFiles.push(file)
        continue
      }

      if ('webPath' in file) {
        cameraFiles.push(file)
        continue
      }
    }

    const content = editorRef.current ? editorRef.current.getText() : ''

    setSaving(true)

    Promise.all([uploadGenericFiles(webFiles), uploadCameraFiles(cameraFiles)])
      .then(async ([uploadedWebFiles, uploadedCameraFiles]) => {
        const municipalityClientId = await getMunicipalityId({ variables: { id: eventItem.municipalityId as string } })

        return addEvent({
          variables: {
            municipalityId: municipalityClientId.data?.municipalities.getMunicipality.id || '',
            input: {
              schedule: {
                startsAt,
              },
              duration,
              location: {
                address: eventItem.address,
              },
              message: {
                title: {
                  lv: eventItem.title,
                },
                content: {
                  lv: content,
                },
                attachedFileIds: [...(uploadedWebFiles || []), ...(uploadedCameraFiles || [])],
                tagIds: eventItem.categoryTags.concat(eventItem.locationTags),
              },
            },
          },
        })
      })
      .then(() => {
        showSnackbar('Pasākums saglabāts!', { severity: 'success' })
        navigate('/events')
      })
      .catch(() => errorHandler())
  }

  const onDeleteImage = (index: number) => {
    const copy = [...fileList]
    copy.splice(index, 1)
    setFileList(copy)
  }

  const onWebImage = (files: File[]) => {
    setFileList([...fileList, ...files])
  }

  const onAppImage = (file: CameraFile) => {
    setFileList([...fileList, file])
  }

  if (loadingMunicipalities) {
    return <FullPageLoader />
  }

  return (
    <EventForm
      ref={editorRef}
      eventItem={eventItem}
      disabled={saving}
      formErrors={formErrors}
      images={images}
      locationTags={locationTags}
      categoryTags={categoryTags}
      municipalities={municipalities}
      loadingTags={loadingTags}
      onChange={onChange}
      onSubmit={onSubmit}
      onDeleteImage={onDeleteImage}
      onWebImage={onWebImage}
      onAppImage={onAppImage}
    />
  )
}
