import React, { useMemo } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

interface SafeAreaWrapProps {
  children: JSX.Element | JSX.Element[]
  sx?: SxProps<Theme>
}

export const SafeAreaWrap = ({ children, sx }: SafeAreaWrapProps) => {
  const style = useMemo(() => {
    const baseStyle = {
      padding:
        'env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0)',
      height: 'calc(100% - env(safe-area-inset-top) -  env(safe-area-inset-bottom))',
    }

    if (Array.isArray(sx)) {
      return Object.assign(baseStyle, ...sx)
    }

    return Object.assign(baseStyle, sx)
  }, [sx])

  return <Box sx={style}>{children}</Box>
}
