import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const ProfileIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 17 19" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill="none"
        d="M1 18V16.9375C1 14.965 1.7612 13.0732 3.11615 11.6784C4.4711 10.2836 6.30881 9.5 8.225 9.5V9.5C10.1412 9.5 11.9789 10.2836 13.3338 11.6784C14.6888 13.0732 15.45 14.965 15.45 16.9375V18M8.225 9.5C9.31996 9.5 10.3701 9.05223 11.1443 8.2552C11.9186 7.45817 12.3536 6.37717 12.3536 5.25C12.3536 4.12283 11.9186 3.04183 11.1443 2.2448C10.3701 1.44777 9.31996 1 8.225 1C7.13003 1 6.07992 1.44777 5.30566 2.2448C4.5314 3.04183 4.09643 4.12283 4.09643 5.25C4.09643 6.37717 4.5314 7.45817 5.30566 8.2552C6.07992 9.05223 7.13003 9.5 8.225 9.5V9.5Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
