import React, { useMemo } from 'react'
import { Box, SxProps, Theme, useTheme } from '@mui/material'

interface PageWrapProps {
  children: JSX.Element | (JSX.Element | undefined)[] | undefined
  sx?: SxProps<Theme>
  useMinHeight?: boolean
}

export const PageWrap = ({ children, sx, useMinHeight = true }: PageWrapProps) => {
  const theme = useTheme() as Theme

  const style = useMemo(() => {
    const baseStyle = {
      p: 2,
      minHeight: useMinHeight ? `calc(100% - ${theme.spacing(4)})` : 'auto',
      height: useMinHeight ? 'auto' : `calc(100% - ${theme.spacing(4)})`,
    }

    if (Array.isArray(sx)) {
      return Object.assign(baseStyle, ...sx)
    }

    return Object.assign(baseStyle, sx)
    // eslint-disable-next-line
  }, [sx])

  return <Box sx={style}>{children}</Box>
}
