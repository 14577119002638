import { Storage, Drivers } from '@ionic/storage'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

const storage = new Storage({
  name: 'db',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
  storeName: 'city',
})

export const initStorage = async () => {
  await storage.defineDriver(CordovaSQLiteDriver)

  return await storage.create()
}

export const saveToStorage = async <T extends unknown>(key: string, value: T): Promise<T> => {
  return await storage.set(key, value)
}

export const readFromStorage = async (key: string) => {
  return await storage.get(key)
}

export const clearStorage = async () => {
  return await storage.clear()
}
