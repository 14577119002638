import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material'
import { MenuIcon } from '../../icons'
import { NormalizedNavLink } from '../NormalizedNavLink'
import { MobillyTxIcon } from '../../icons'
import { Capacitor } from '@capacitor/core'

interface HeaderProps {
  onMenuToggle: () => void
}

export const Header = ({ onMenuToggle }: HeaderProps) => {
  const matches = useMediaQuery('(min-width:360px)')
  const width = Capacitor.getPlatform() === 'web' ? '150px' : 'auto'

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{
        paddingTop: 'env(safe-area-inset-top, 0)',
        paddingRight: 'env(safe-area-inset-right, 0)',
        paddingLeft: 'env(safe-area-inset-left, 0)',
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ pl: 0, height: '72px' }}>
        <NormalizedNavLink to="/" style={{ width: '4rem', position: 'absolute' }}>
          <MobillyTxIcon height="42.62px" width={width} primaryColor={'#B455A0'} secondaryColor={'#414042'} />
        </NormalizedNavLink>
        <IconButton
          size="large"
          onClick={onMenuToggle}
          aria-label="Atvērt izvēlni"
          sx={{ position: 'absolute', right: 0, pr: '2vw' }}
        >
          <MenuIcon size={matches ? 'medium' : 'small'} color={'primary'} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
