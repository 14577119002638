import { Box, Button, Divider, List, ListItem, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { mapEvent } from '../../utils'
import { Comments, ContentWrap, FullPageLoader, Row, StaticGallery, TagList } from '../../components'
import { GENERIC_ERROR } from '../../constants'
import { useDeleteEventMutation, useGetEventQuery } from '../../graphql'
import { useConfirmStore, useSnackbarStore } from '../../store'
import { parseDateObj } from '../../utils'
import { EventEntry, StringObject } from '../../interfaces'

export const EventItem = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { loading, error, data } = useGetEventQuery({ variables: { id: id as string } })
  // const [cancelEvent, { loading: cancelingEvent, error: eventCancelationError }] = useCancelEventMutation()
  const [deleteEvent, { loading: deletingEvent }] = useDeleteEventMutation()

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const confirm = useConfirmStore((state) => state.confirm)

  const [eventItem, setEventItem] = useState<EventEntry | null>(null)
  const tagLabels = useRef<StringObject>({})

  useEffect(() => {
    if (data) {
      const [entry, tagMap] = mapEvent(data)

      if (tagMap) {
        tagLabels.current = tagMap
      }

      setEventItem(entry)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const publishedAt = useMemo(() => {
    if (eventItem) {
      return parseDateObj(eventItem.publishedAt)
    }

    return ''
  }, [eventItem])

  // const onCancelEvent = async () => {

  //   const response = await confirm({
  //     promptTitle: 'Vai tiešām atcelt šo pasākumu?',
  //     confirmButtonColor: 'error',
  //     confirmButtonLabel: 'Atcelt',
  //   })

  //   if (response && eventItem) {
  //     cancelEvent({ variables: { id: eventItem.id } })
  //       .then(() => {
  //         showSnackbar('Pasākums atcelts!', { severity: 'success' })
  //         navigate('/events')
  //       })
  //   }
  // }
  // disabled until we implement event cancelation status in other products

  const onCloseEntry = () => {
    navigate(-1)
  }

  const onDelete = async () => {
    const response = await confirm({
      promptTitle: 'Vai tiešām dzēst šo pasākumu?',
      confirmButtonColor: 'error',
      confirmButtonLabel: 'Dzēst',
    })

    if (response && eventItem) {
      deleteEvent({ variables: { id: eventItem.id } }).then(() => {
        navigate('/events')
      })
    }
  }

  const onEdit = () => {
    if (eventItem) {
      navigate('/events/edit/' + eventItem.id)
    }
  }

  useEffect(() => {
    if (error) {
      showSnackbar(GENERIC_ERROR, { severity: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <>
      {eventItem ? (
        <Box sx={{ width: '100%', '>div, >form': { mt: 2 } }}>
          <Row label="Nosaukums">
            <Typography variant="h1" sx={{ fontSize: 'inherit' }}>
              {eventItem.title}
            </Typography>
          </Row>
          <Row label="Apraksts">
            <ContentWrap content={eventItem.content} />
          </Row>
          <Row label="Kategorija">
            <TagList tags={eventItem.categoryTags} tagLabels={tagLabels.current} />
          </Row>
          <Row label="Atrašanās vieta">
            <TagList tags={eventItem.locationTags} tagLabels={tagLabels.current} />
          </Row>
          <Row label="Adrese">
            <Typography>{eventItem.address}</Typography>
          </Row>
          <Row label="Norises laiks">
            <Typography>{eventItem.durationInterval}</Typography>
          </Row>
          <Row label="Publicēšanas datums">
            <Typography>{publishedAt}</Typography>
          </Row>
          {eventItem.canceledAt && (
            <Row label="Atcelšanas datums">
              <Typography>{eventItem.canceledAt}</Typography>
            </Row>
          )}
          <Row label="Attēli">
            {eventItem.images && eventItem.images.length > 0 ? <StaticGallery images={eventItem.images} /> : <></>}
          </Row>
          <Box sx={{ textAlign: 'right', '>button': { ml: 2 } }}>
            {/* {
                eventItem.publishedAt &&
                !eventItem.canceledAt &&
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={cancelingEvent || deletingEvent}
                  onClick={onCancelEvent}>
                  Atcelt
                </Button>
              } */}
            {eventItem.publishedAt === null && (
              <Button onClick={onEdit} variant="contained" color="info">
                Labot
              </Button>
            )}
            <Button variant="contained" color="error" disabled={deletingEvent} onClick={onDelete}>
              Dzēst
            </Button>
            <Button variant="outlined" color="primary" disabled={deletingEvent} onClick={onCloseEntry}>
              Aizvērt
            </Button>
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box>
            <List sx={{ display: 'flex', '>li': { width: 'auto', mr: 2 } }} disablePadding>
              <ListItem>Varbūt {eventItem.maybe}</ListItem>
              <ListItem>Apmeklēšu {eventItem.going}</ListItem>
              <ListItem>Neapmeklēšu {eventItem.notGoing}</ListItem>
            </List>
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Comments comments={eventItem.comments} />
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
