import DatePicker from 'react-datepicker'
import { Box, TextField, InputAdornment, useMediaQuery, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import lv from 'date-fns/locale/lv'
import { DEFAULT_DATE_FORMAT, VIEWPORT_MD } from '../../constants'
import { CalendarIcon } from '../../icons'
import { FILTER_BUTTON_STYLE } from '../../style/sx'
import { RangeFilter } from '../../enums'

registerLocale('lv', lv)

interface DateRangeFilterProps {
  disabled: boolean
  value: RangeFilter
  startDate: Date | null
  endDate: Date | null
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: RangeFilter) => void
  onDateChange: (dates: [Date | null, Date | null]) => void
}

export const DateRangeFilter = ({ disabled, value, startDate, endDate, onDateChange, onChange }: DateRangeFilterProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)
  const width = matches ? '285px' : '100%'

  return (
    <Box sx={{ mb: 2, width }}>
      <DatePicker
        locale="lv"
        dateFormat={DEFAULT_DATE_FORMAT}
        selectsRange
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
        customInput={
          <TextField
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon size="small" color="primary" />
                </InputAdornment>
              ),
            }}
          />
        }
      />
      <ToggleButtonGroup
        onChange={onChange}
        value={value}
        exclusive
        sx={{
          mt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ToggleButton value={RangeFilter.TODAY} disabled={disabled} sx={[FILTER_BUTTON_STYLE, { mr: 1 }]}>
          Šodien
        </ToggleButton>
        <ToggleButton value={RangeFilter.CURRENT_WEEK} disabled={disabled} sx={[FILTER_BUTTON_STYLE, { mr: 1 }]}>
          Šonedēļ
        </ToggleButton>
        <ToggleButton value={RangeFilter.CURRENT_MONTH} disabled={disabled} sx={[FILTER_BUTTON_STYLE]}>
          Šomēnes
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}
