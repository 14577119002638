import { Button, Theme, useMediaQuery } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { VIEWPORT_MD } from '../../constants'

interface FooterButtonProps {
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  onClick: () => void
  children?: JSX.Element | JSX.Element[]
  buttonLabel: string
  color?: 'primary' | 'secondary'
  disabled?: boolean
  fullWidth?: boolean
}

export const FooterButton = ({
  sx = {},
  onClick,
  children,
  buttonLabel,
  color = 'secondary',
  disabled,
  fullWidth,
}: FooterButtonProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)
  const widthWithBackButton = fullWidth ? '100%' : '85%'
  const width = matches ? '576px' : widthWithBackButton

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={[{ width, mt: 'auto', color: 'accent.main', textTransform: 'none' }, sx]}
      color={color}
      size="large"
      disabled={disabled}
    >
      {children}
      {buttonLabel}
    </Button>
  )
}
