import { format } from 'date-fns'
import { useMemo } from 'react'
import { DEFAULT_DATE_TIME_FORMAT } from '../../constants'

interface DateCellProps {
  date: Date | null
}

export const DateCell = ({ date }: DateCellProps) => {
  const result = useMemo(() => {
    if (date) {
      return <>{format(date, DEFAULT_DATE_TIME_FORMAT)}</>
    }

    return <></>
  }, [date])

  return result
}
