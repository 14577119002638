import { Snackbar as SnackbarBase, Alert, SxProps } from '@mui/material'
import { useSnackbarStore } from '../../store'

export const Snackbar = () => {
  const { open, message, configuration, closeSnackbar } = useSnackbarStore()

  const getSafeAreaOffset = () => {
    const sx: SxProps = {
      paddingRight: 'env(safe-area-inset-right, 0)',
      paddingLeft: 'env(safe-area-inset-left, 0)',
    }

    if (configuration.vertical === 'top') {
      sx['paddingTop'] = 'env(safe-area-inset-top, 0)'
    } else {
      sx['paddingBottom'] = 'env(safe-area-inset-bottom, 0)'
    }

    return sx
  }

  return (
    <SnackbarBase
      anchorOrigin={{
        vertical: configuration.vertical,
        horizontal: configuration.horizontal,
      }}
      autoHideDuration={configuration.autoHideDuration}
      open={open}
      onClose={closeSnackbar}
      message={message}
      sx={getSafeAreaOffset}
      key={configuration.vertical + configuration.horizontal}
    >
      <Alert severity={configuration.severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </SnackbarBase>
  )
}
