import { GetAggregatedOffersQuery } from '../../graphql'
import { AggregatedUtilizationsItem } from '../../interfaces'

export const mapAggregatedUtilizations = (queryResult: GetAggregatedOffersQuery): AggregatedUtilizationsItem[] => {
  try {
    const aggregatedUtilizationsList = queryResult.loyalty.getAggregatedOffers.map((item) => {
      return {
        id: item.historicalOffer.id,
        title: item.historicalOffer.message.title?.all.lv,
        utilizationCount: item.utilizationsAggregation.count,
      }
    })

    aggregatedUtilizationsList.sort((a, b) => b.utilizationCount - a.utilizationCount)

    return aggregatedUtilizationsList
  } catch {
    return []
  }
}
