import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const EventIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 19 19" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill="none"
        d="M11.7937 2.78947V1M11.7937 2.78947V4.57895M11.7937 2.78947H7.74603M1 8.15789V4.57895C1 4.10435 1.18953 3.64919 1.5269 3.3136C1.86426 2.97801 2.32183 2.78947 2.79894 2.78947H4.59788M4.59788 1V4.57895M17.1905 8.15789V4.57895C17.1905 4.10435 17.0009 3.64919 16.6636 3.3136C16.3262 2.97801 15.8686 2.78947 15.3915 2.78947H14.9418M1 8.15789V16.2105C1 16.6851 1.18953 17.1403 1.5269 17.4759C1.86426 17.8115 2.32183 18 2.79894 18H15.3915C15.8686 18 16.3262 17.8115 16.6636 17.4759C17.0009 17.1403 17.1905 16.6851 17.1905 16.2105V8.15789H1Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
