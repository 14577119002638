export const sortByCreatedAt = <T extends { createdAt: Date | null }>(list: T[], direction: 'asc' | 'dsc'): T[] => {
  return list.sort((a, b) => {
    const aItem = a.createdAt
    const bItem = b.createdAt

    if (aItem instanceof Date && bItem instanceof Date) {
      if (aItem.getTime() < bItem.getTime()) {
        return direction === 'asc' ? -1 : 1
      }

      if (aItem.getTime() > bItem.getTime()) {
        return direction === 'asc' ? 1 : -1
      }

      return 0
    }

    return 0
  })
}
