import create from 'zustand'
import { ConfirmConfig } from '../interfaces'

const defaultConfiguration: ConfirmConfig = {
  confirmButtonColor: 'success',
  confirmButtonLabel: 'common.confirm',
  rejectButtonLabel: 'common.close',
}

interface ConfirmStore {
  open: boolean
  config: ConfirmConfig
  resolveCallback?: (value: boolean | PromiseLike<boolean>) => void
  confirm: (config: Partial<ConfirmConfig>) => Promise<boolean>
  closeConfirm: () => void
}

export const useConfirmStore = create<ConfirmStore>((set) => ({
  open: false,
  config: defaultConfiguration,
  confirm: (config: Partial<ConfirmConfig>) => {
    set(() => ({
      config: {
        ...defaultConfiguration,
        ...config,
      },
      open: true,
    }))

    return new Promise<boolean>((resolve) => {
      set(() => ({ resolveCallback: resolve }))
    })
  },
  closeConfirm: () => {
    set(() => ({
      open: false,
      resolveCallback: undefined,
    }))
  },
}))
