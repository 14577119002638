import { Box, Button } from '@mui/material'
import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import { useEffect, useState } from 'react'
import { FLEX_DIRECTION_COLUMN } from '../../style/sx'
import { useVerifyStore } from '../../store'
import { useNavigate } from 'react-router-dom'

export const Scanner = () => {
  const navigate = useNavigate()

  const { setRawQrCode, reset } = useVerifyStore()

  const [scanActive, setScanActive] = useState(true)

  useEffect(() => {
    reset()

    const checkPermission = async () => {
      // check or request permission
      const status = await BarcodeScanner.checkPermission({ force: true })

      if (status.granted) {
        return true
      }

      return false
    }

    const startScan = async () => {
      setScanActive(true)

      BarcodeScanner.hideBackground() // make background of WebView transparent

      const result = await BarcodeScanner.startScan()

      if (result.hasContent) {
        setRawQrCode(result.content)
        navigate('/verify', { replace: true })
      }
    }

    const stopScan = () => {
      BarcodeScanner.showBackground()
      BarcodeScanner.stopScan()
      setScanActive(false)
    }

    checkPermission().then((resp) => {
      if (resp) {
        startScan()
      }
    })

    return () => {
      if (scanActive) {
        stopScan()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBack = () => {
    navigate('/')
  }

  return (
    <Box component="main" sx={FLEX_DIRECTION_COLUMN}>
      {scanActive && (
        <Box
          sx={{
            border: '2px solid #fff',
            boxShadow: '0 0 0 100vmax rgba(0,0,0,0.5)',
            left: '50%',
            height: '70vw',
            width: '70vw',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        ></Box>
      )}
      <Button sx={{ mt: 'auto' }} size="large" variant="contained" onClick={onBack}>
        Aizvērt
      </Button>
    </Box>
  )
}
