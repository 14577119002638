import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const OffersIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 15 23" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill="none"
        d="M4.1504 18.5H10.4495M5.20025 22H9.39967M4.1504 15C4.15145 12.6667 3.62652 12.0833 2.57561 10.9167C1.52576 9.75 1.02603 8.56817 1.00083 6.83333C0.951488 3.275 3.10054 1 7.29996 1C11.5004 1 13.6505 3.275 13.5991 6.83333C13.5749 8.56817 13.0742 9.75 12.0243 10.9167C10.9755 12.0833 10.4506 12.6667 10.4495 15"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
