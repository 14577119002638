import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ItemButton, InfiniteList, StickyFooter, FooterButton, BackButton, FilterToggleButtons } from '../../components'
import { EventsListItem } from '../../interfaces'
import { mapEventsList } from '../../utils'
import { DEFAULT_ENTRIES_PER_PAGE, VIEWPORT_MD } from '../../constants'
import { useDeleteEventMutation, useGetEventsListQuery, PublicEventsTemporalFilterOptionPev } from '../../graphql'
import { useConfirmStore } from '../../store'
import AddIcon from '@mui/icons-material/Add'
import { Box, useMediaQuery } from '@mui/material'

export const EventsHome = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const matches = useMediaQuery(VIEWPORT_MD)

  const [filter, setFilter] = useState(PublicEventsTemporalFilterOptionPev.Unexpired)
  const { data, loading, refetch } = useGetEventsListQuery({ variables: { filter: { temporal: filter } } })
  const [eventList, setEventList] = useState<EventsListItem[]>([])
  const [pagedEventList, setPagedEventList] = useState<EventsListItem[]>([])
  const [deleteEvent] = useDeleteEventMutation()
  const confirm = useConfirmStore((state) => state.confirm)

  useEffect(() => {
    if (data) {
      const list = mapEventsList(data)

      setEventList(list)
      setPagedEventList(list.slice(0, DEFAULT_ENTRIES_PER_PAGE))
    }
  }, [data])

  const onFilterChange = (event: React.MouseEvent<HTMLElement>, value: PublicEventsTemporalFilterOptionPev) => {
    if (value !== null) {
      setFilter(value)
    }
  }

  const onNewEvent = () => {
    navigate('/events/new')
  }

  const onEdit = (id: string) => {
    navigate('/events/edit/' + id)
  }

  const onView = (id: string) => {
    navigate('/events/item/' + id)
  }

  const onDelete = async (id: string) => {
    const response = await confirm({
      promptTitle: 'Vai tiešām dzēst šo pasākumu?',
      confirmButtonColor: 'error',
      confirmButtonLabel: 'Dzēst',
    })

    if (response && id) {
      deleteEvent({ variables: { id: id } }).then(() => {
        refetch()
      })
    }
  }

  const onLoadMore = () => {
    const nextPage = currentPage + 1
    const nextEntriesCount = nextPage * DEFAULT_ENTRIES_PER_PAGE

    setCurrentPage(nextPage)

    if (nextEntriesCount >= eventList.length) {
      setPagedEventList(eventList)
      return
    }

    setPagedEventList(eventList.slice(0, nextEntriesCount))
  }

  const renderListItem = (eventItem: EventsListItem) => {
    return (
      <ItemButton
        title={eventItem.title}
        id={eventItem.id}
        preview={eventItem.preview}
        disabled={eventItem.publishedAt !== null}
        onView={() => onView(eventItem.id)}
        onEdit={() => onEdit(eventItem.id)}
        onDelete={() => onDelete(eventItem.id)}
        validityInterval={eventItem.validityInterval}
        approvedAt={eventItem.publishedAt !== null}
      />
    )
  }

  return (
    <>
      <StickyFooter>
        <FooterButton onClick={onNewEvent} buttonLabel="Pievienot jaunu">
          <AddIcon />
        </FooterButton>
        {!matches ? <BackButton /> : <></>}
      </StickyFooter>
      <FilterToggleButtons
        onChange={onFilterChange}
        value={filter}
        disabled={loading}
        filterEnum={PublicEventsTemporalFilterOptionPev}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <InfiniteList
          width={matches ? '80%' : '100%'}
          loading={loading}
          renderFn={renderListItem}
          onLoadMore={onLoadMore}
          list={pagedEventList}
        />
      </Box>
    </>
  )
}
