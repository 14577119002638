export const passwordRequirements = [
  'jābūt vismaz 10 (desmit) simbolu garai',
  'nedrīkst saturēt vairāk par 64 (sešdesmit četriem) simboliem',
  'jāsatur lielais latīņu alfabēta burts',
  'jāsatur mazais latīņu alfabēta burts',
  'jāsatur īpašais simbols',
  'jāsatur cipars',
  'nevar saturēt lietotājvārdu',
  'nevar sakrist ar veco paroli',
]
