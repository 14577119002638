import { Box, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

interface ResultWrapProps {
  background?: string
  sx?: SystemStyleObject<Theme>
  children: JSX.Element | JSX.Element[]
}

export const ResultWrap = ({ background, sx = {}, children }: ResultWrapProps) => {
  return (
    <Box
      sx={[
        {
          background,
          height: '100%',
          m: -2,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          overflow: 'hidden',
          position: 'relative',
        },
        sx,
      ]}
    >
      {children}
    </Box>
  )
}
