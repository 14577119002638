import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DelayedLoader, PageWrap, SafeAreaWrap } from '../../components'
import { useUserContext } from '../../store'
import { clearStorage } from '../../utils'

export const Logout = () => {
  const navigate = useNavigate()
  const resetUserContext = useUserContext((state) => state.resetUserContext)

  useEffect(() => {
    resetUserContext()

    clearStorage().then(() => {
      setTimeout(() => {
        navigate('/', { replace: true })
      }, 1000) // delay added to imitate wait for API response
    })
    // eslint-disable-next-line
  }, [])

  return (
    <SafeAreaWrap sx={{ height: '100%' }}>
      <PageWrap sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <DelayedLoader />
      </PageWrap>
    </SafeAreaWrap>
  )
}
