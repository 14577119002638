import { FormEvent, useState } from 'react'
import { Box, TextField, useMediaQuery, Typography, List, ListItem, IconButton, Collapse, Paper } from '@mui/material'
import { FormRow, FormActions, TogglePasswordVisibility } from '../../components'
import { PasswordFormData, PasswordVisibilityState } from '../../interfaces'
import { useSnackbarStore, useUserContext } from '../../store'
import { validatePassword } from '../../utils'
import { passwordRequirements, VIEWPORT_MD } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { useChangePassword } from '../../api'
import { saveToStorage } from '../../utils'
import { StorageKey } from '../../enums'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

export const ChangePassword = () => {
  const navigate = useNavigate()
  const matches = useMediaQuery(VIEWPORT_MD)
  const web = useMediaQuery('(min-width:840px)')
  const flexDirection = web ? 'row' : 'column'
  const width = matches ? '60%' : '80%'
  const defaultListState = matches ? true : false
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const setToken = useUserContext((state) => state.setToken)
  const username = useUserContext((state) => state.username)
  const { changePassword } = useChangePassword()

  const [inputType, setInputType] = useState<PasswordVisibilityState>({
    oldPassword: 'password',
    newPassword: 'password',
    newPasswordConfirm: 'password',
  })

  const [saving, setSaving] = useState(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [formData, setFormData] = useState<PasswordFormData>({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  })

  const [open, setOpen] = useState(defaultListState)

  const handleClick = () => {
    setOpen(!open)
  }

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const [isInvalid, errors] = validatePassword(formData, username)
    setFormErrors(errors)

    if (isInvalid) {
      return
    }
    setSaving(true)

    changePassword(formData.oldPassword, formData.newPassword)
      .then((token) => saveToStorage(StorageKey.Token, token))
      .then((token) => {
        token && setToken(token)
        showSnackbar('Parole nomainīta veiksmīgi!', { severity: 'success' })
        navigate('/profile', { replace: true })
      })
      .catch(() => {
        setSaving(false)
        showSnackbar('Notikusi kļūda! Lūdzu pārliecinies, ka parole atbilst visām prasībām un mēģini vēlreiz!', {
          severity: 'error',
        })
      })
  }

  const showPassword = (field: string) => {
    setInputType((prevInputType) => ({
      ...prevInputType,
      [field]: prevInputType[field as keyof PasswordVisibilityState] === 'password' ? 'text' : 'password',
    }))
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  return (
    <Box
      onSubmit={onSubmit}
      component="form"
      sx={{
        position: 'absolute',
        height: 'calc(100vh - 72px - env(safe-area-inset-top, 0))',
        width: '100vw',
        top: 'calc(72px + env(safe-area-inset-top, 0))',
        right: 0,
        overflow: 'hidden',
        ':before': {
          width: '1057px',
          height: '1057px',
          background: 'linear-gradient(150.67deg, #B455A0 11.53%, #8E5BA6 49.44%)',
          borderRadius: '50%',
          left: '-22.36%',
          right: '-171.25%',
          top: '27.38%',
          bottom: '-59.54%',
          position: 'absolute',
          content: '""',
        },
      }}
    >
      <Typography variant="h1" textAlign="center" sx={{ mt: 2 }}>
        Paroles maiņa
      </Typography>
      <Paper
        elevation={3}
        sx={{
          overflowY: 'auto',
          maxHeight: '70vh',
          width,
          mt: 2,
          ml: 'auto',
          mr: 'auto',
          left: 0,
          right: 0,
          p: 2,
          position: 'absolute',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', '>div, >label': { mt: 2 } }}>
            <FormRow label="Vecā parole">
              <TextField
                name="oldPassword"
                disabled={saving}
                type={inputType.oldPassword}
                value={formData.oldPassword}
                onChange={onChange}
                helperText={formErrors['oldPassword']}
                error={!!formErrors['oldPassword']}
                InputProps={{
                  endAdornment: (
                    <TogglePasswordVisibility onShowPassword={() => showPassword('oldPassword')} type={inputType.oldPassword} />
                  ),
                }}
              />
            </FormRow>
            <FormRow label="Jaunā parole">
              <TextField
                name="newPassword"
                disabled={saving}
                type={inputType.newPassword}
                value={formData.newPassword}
                onChange={onChange}
                helperText={formErrors['newPassword']}
                error={!!formErrors['newPassword']}
                InputProps={{
                  endAdornment: (
                    <TogglePasswordVisibility onShowPassword={() => showPassword('newPassword')} type={inputType.newPassword} />
                  ),
                }}
              />
            </FormRow>
            <FormRow label="Jaunā parole atkārtoti">
              <TextField
                name="newPasswordConfirm"
                disabled={saving}
                type={inputType.newPasswordConfirm}
                value={formData.newPasswordConfirm}
                onChange={onChange}
                helperText={formErrors['newPasswordConfirm']}
                error={!!formErrors['newPasswordConfirm']}
                InputProps={{
                  endAdornment: (
                    <TogglePasswordVisibility
                      onShowPassword={() => showPassword('newPasswordConfirm')}
                      type={inputType.newPasswordConfirm}
                    />
                  ),
                }}
              />
            </FormRow>
          </Box>
          <Box sx={{ ml: web ? 2 : 0 }}>
            <Typography sx={{ display: 'flex', flexDirection: 'row', pt: matches ? 2 : 1 }}>
              Norādītajai parolei ir jāatbilst sekojošiem kritērijiem:
              {!web && <IconButton onClick={handleClick}> {open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
            </Typography>
            <List sx={{ listStyleType: 'disc', fontSize: '0.9rem' }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {passwordRequirements.map((requirement, index) => (
                  <ListItem key={index} sx={{ ml: 2, pl: 0, pt: 0, display: 'list-item' }}>
                    <Typography>{requirement}</Typography>
                  </ListItem>
                ))}
              </Collapse>
            </List>
          </Box>
        </Box>
        <FormActions disabled={saving} />
      </Paper>
    </Box>
  )
}
