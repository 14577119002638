import { Box, Button, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { VIEWPORT_MD } from '../../constants'
import { BackButton } from '../BackButton'

interface FormActionsProps {
  disabled: boolean
}

export const FormActions = ({ disabled }: FormActionsProps) => {
  const navigate = useNavigate()
  const matches = useMediaQuery(VIEWPORT_MD)
  const width = matches ? undefined : '85%'

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Box
      sx={{
        pt: 4,
        textAlign: matches ? 'right' : undefined,
        pb: 4,
        justifyContent: matches ? undefined : 'space-between',
        display: matches ? 'block' : 'flex',
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        disabled={disabled}
        fullWidth={!matches}
        sx={{ width }}
        size="large"
        type="submit"
      >
        Saglabāt
      </Button>
      {matches ? (
        <Button
          variant="outlined"
          disabled={disabled}
          fullWidth={!matches}
          size="large"
          sx={{
            mt: matches ? undefined : 2,
            ml: matches ? 2 : undefined,
            mb: 0,
          }}
          onClick={goBack}
        >
          Atpakaļ
        </Button>
      ) : (
        <BackButton />
      )}
    </Box>
  )
}
