import { Typography } from '@mui/material'

export const TocContent = () => {
  return (
    <>
      <Typography variant="h2">Sadarbības partnera mobilās lietotnes lietošanas noteikumi</Typography>

      <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
        1. Termini
      </Typography>
      <Typography>
        1.1. Iedzīvotājs – fiziska persona, kas ieguvusi Pašvaldības izsniegtu unikālu kvadrātkodu, kas ļauj pārliecināties par
        fiziskās personas iespējām saņemt noteiktai iedzīvotāju grupai paredzētus Piedāvājumus.
      </Typography>
      <Typography>
        1.2. Lietotājs – fiziska persona, kuru Piedāvājuma sniedzējs vai tā pārstāvis pilnvarojis lietot Lietotni Piedāvājuma
        sniedzēja vārdā.
      </Typography>
      <Typography>1.3. Lietotne – šī mobilā lietotne.</Typography>
      <Typography>
        1.4. Lietotnes uzturētājs – Lietotnes izstrādātājs un tehniskais nodrošinātājs SIA "MobillyTX", reģistrācijas numurs:
        50003799751, juridiskā adrese Latvija, Rīga, Dzirnavu iela 91 k-3 - 20.
      </Typography>
      <Typography>
        1.5. Pašvaldība – Latvijas Republikas pašvaldība, kas ieviesusi un izmanto ar Lietotni saderīgu platformu, kurā
        iespējams publicēt Piedāvājumus.
      </Typography>
      <Typography>
        1.6. Piedāvājums – Piedāvājuma sniedzēja sniegto pakalpojumu vai preču piedāvājums, kas paredzēts Iedzīvotājiem vai
        atsevišķām Iedzīvotāju grupām, Pašvaldības platformā.
      </Typography>
      <Typography>
        1.7. Piedāvājuma sniedzējs – sadarbības partneris vai Pašvaldība, kas vēlas sniegt Iedzīvotājiem vai atsevišķām
        Iedzīvotāju grupām, savus pakalpojumus vai preces ar labvēlīgākiem nosacījumiem.
      </Typography>

      <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
        2. Vispārīgie noteikumi
      </Typography>
      <Typography>
        2.1. Lietotnes mērķis ir atbalstīt komercdarbību Latvijas reģionos, Piedāvājuma sniedzējiem nodrošinot iespēju publicēt
        to preču vai pakalpojumu Piedāvājumus Pašvaldību platformās.
      </Typography>
      <Typography>
        2.2. Piedāvājuma sniedzēji izmanto Lietotni, lai publicētu un administrētu Piedāvājumus, nolasītu Iedzīvotāju
        kvadrātkodus un apstiprinātu Iedzīvotāja statusu, kas ļauj saņemt konkrētu Piedāvājumu.
      </Typography>
      <Typography>
        2.3. Šie Lietošanas noteikumi nosaka ar Lietotnes un tajā ietverto iespēju izmantošanu saistītus jautājumus.
      </Typography>
      <Typography>
        2.4. Lietošanas noteikumi piemērojami visiem Lietotājiem. Pirms Lietotnes izmantošanas uzsākšanas Lietotājam ir
        pienākums pilnībā iepazīties ar šiem noteikumiem. Lejupielādējot un lietojot Lietotni, tiek uzskatīts, ka Lietotājs ir
        iepazinies un apņemas ievērot šos Lietošanas noteikumus.
      </Typography>
      <Typography>
        2.5. Lietotājam ir pienākums pilnībā ievērot spēkā esošo normatīvo aktu prasības, jo īpaši attiecībā uz publicējamās
        informācijas saturu un citu personu tiesību ievērošanu.
      </Typography>
      <Typography>
        2.6. Piedāvājuma sniedzējs ir pilnībā atbildīgs par jebkuru informāciju, kura no Lietotāja profila ir ievietota
        Lietotnē, un par visām darbībām, kuras no Lietotāja profila ir veiktas ar Lietotnes starpniecību.
      </Typography>
      <Typography>
        2.7. Lietotnes uzturētājam ir tiesības dzēst jebkādu Lietotāja ievietoto saturu, ja Lietotnes uzturētāja ieskatā tas
        neatbilst normatīvo aktu prasībām vai ētikas normām.
      </Typography>

      <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
        3. Lietotāju veidi, tiesības un pienākumi
      </Typography>
      <Typography>
        3.1. Lietotājs – Pārvaldnieks, ir Piedāvājuma sniedzēja pārstāvis, kam Lietotnē piešķirtas tiesības pievienot citus
        Piedāvājuma sniedzēja pārstāvjus un Piedāvājuma izmantošanas vietas, Piedāvājuma sniedzēja vārdā izveidot un iesniegt
        Piedāvājumu, skenēt Iedzīvotāja kvadrātkodu un pārliecināties par Iedzīvotāja tiesībām saņemt Piedāvājumu.
      </Typography>
      <Typography>
        3.2. Lietotājs – Administrators, ir Piedāvājuma sniedzēja pārstāvis, kam ir tiesības Piedāvājuma sniedzēja vārdā
        izveidot un iesniegt Piedāvājumu, skenēt Iedzīvotāja kvadrātkodu un pārliecināties par Iedzīvotāja tiesībām saņemt
        Piedāvājumu.
      </Typography>
      <Typography>
        3.3. Lietotājs – Pārdevējs, ir Piedāvājuma sniedzēja pārstāvis, kam ir tiesības skenēt Iedzīvotāja kvadrātkodu un
        pārliecināties par Iedzīvotāja tiesībām saņemt Piedāvājumu.
      </Typography>
      <Typography>
        3.4. Lietotāja pienākums ir nepieļaut autentifikācijas datu izpaušanu trešajām personām un šo personu piekļuvi saviem
        datiem.
      </Typography>
      <Typography>
        3.5. Lietotājiem nav tiesību saglabāt informāciju par Iedzīvotājiem, to statusu vai izmantotajiem Piedāvājumiem, ja vien
        to neparedz Lietotnes funkcionalitāte (piemēram, anonimizēta Piedāvājumu izmantošanas statistika).
      </Typography>
      <Typography>
        3.6. Lietotājam ir tiesības un arī pienākums neskaidrību gadījumos vai arī gadījumos, kad tas ir atklājis datu
        neprecizitātes vai arī aplikācijas nekorektu darbību, ziņot par to Lietotnes uzturētājam.
      </Typography>
      <Typography>
        3.7. Lietotāja apliecinājums par Lietotnes noteikumu ievērošanu stājas spēkā ar brīdi, kad Lietotājs elektroniski
        apstiprinājis savu piekrišanu šiem noteikumiem. Apliecinājums ir spēkā visā Lietotnes darbības laikā.
      </Typography>

      <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
        4. Lietotnes uzturētāja tiesības, pienākumi un atbildība
      </Typography>
      <Typography>
        4.1. Lietotnes uzturētājs nodrošina Lietotnes pareizu darbību, tās atjauninājumu izstrādi, ieviešanu un jaunu
        Piedāvājuma sniedzēju reģistrēšanu.
      </Typography>
      <Typography>
        4.2. Lietotnes uzturētājs ir tiesīgs vienpusēji veikt izmaiņas Lietotnes funkcionalitātē, lietošanas kārtībā vai
        Lietotāju tiesību apjomā.
      </Typography>
      <Typography>
        4.3. Lietotnes uzturētājs nav atbildīgs par Piedāvājuma sniedzēja jebkādiem zaudējumiem, kas radušies, Piedāvājumu
        nepublicēšanas Pašvaldību platformās dēļ, kā arī Piedāvājumu satura un tā atbilstības normatīvo aktu prasībām dēļ.
      </Typography>
      <Typography>
        4.4. Lietotnes uzturētājs nav atbildīgs par traucējumiem Lietotnes darbībā, kas radušies no Lietotnes uzturētāja
        neatkarīgu elektronisko sakaru traucējumu vai Lietotāja neprasmīgas rīcības dēļ.
      </Typography>
      <Typography>
        4.5. Lietotnes uzturētājam ir tiesības bez iepriekšēja brīdinājuma, nekavējoties apturēt Lietotāja konta darbību, ja
        Lietotnes uzturētājam rodas pamatotas aizdomas par to, ka Lietotājs pārkāpj vai nepilda šos noteikumus vai ar savām
        darbībām bloķē vai traucē lietotnes darbību.
      </Typography>
      <Typography>
        4.6. Lietotnes uzturētājs nav atbildīgs par zaudējumiem, kas saistīti ar neparedzētiem un nepārvaramas varas apstākļiem
        (force majeure) – stihiskām nelaimēm, tostarp ugunsgrēkiem, plūdiem, vai no tiem neatkarīgu trešo personu darbību –
        valsts normatīvo aktu izmaiņām, karadarbību, streikiem, masu nekārtībām u.c.
      </Typography>

      <Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
        5. Citi noteikumi
      </Typography>
      <Typography>5.1. Lietotnes uzturētājs ir tiesīgs vienpusēji grozīt un papildināt šos noteikumus.</Typography>
      <Typography>
        5.2. Noteikumi, to grozījumi vai papildinājumi stājas spēkā ar noteikumu grozījumi vai papildinājumu publicēšanas brīdi
        Lietotnē.
      </Typography>
      <Typography>
        5.3. Visi strīdi un neskaidrības, kas rodas saistībā ar šiem noteikumiem ir risināmi pārrunu ceļā. Ja radušos strīdu
        nevar atrisināt pārrunu ceļā, tas tiek risināts Latvijas Republikas normatīvajos aktos noteiktajā kārtībā.
      </Typography>
    </>
  )
}
