import { Dialog, DialogActions, Button } from '@mui/material'
import { FLEX_DIRECTION_COLUMN } from '../../style/sx'
import { PageWrap } from '../PageWrap'
import { SafeAreaWrap } from '../SafeAreaWrap'

interface FullScreenImagePreviewProps {
  open: boolean
  previewSrc: { src: string; alt?: string }
  onClose: () => void
}

export const FullScreenImagePreview = ({ open, previewSrc, onClose }: FullScreenImagePreviewProps) => {
  return (
    <Dialog open={open} fullScreen>
      <SafeAreaWrap sx={{ height: '100%' }}>
        <PageWrap sx={[FLEX_DIRECTION_COLUMN, { justifyContent: 'space-between' }]}>
          <img
            src={previewSrc.src}
            alt={previewSrc.alt}
            style={{ marginTop: 'auto', marginBottom: 'auto', width: '100%', height: 'auto' }}
          />
          <DialogActions sx={{ flexDirection: 'column', p: 0, mt: 2 }}>
            <Button variant="outlined" onClick={onClose}>
              Aizvērt
            </Button>
          </DialogActions>
        </PageWrap>
      </SafeAreaWrap>
    </Dialog>
  )
}
