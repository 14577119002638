import { useAuthenticateMutation } from '../../graphql'

export const useAuthenticate = () => {
  const [authenticate, { loading, error }] = useAuthenticateMutation()

  return {
    authenticate: (username: string, password: string) => {
      return authenticate({
        variables: {
          username,
          password,
        },
      }).then(({ data }) => {
        return data?.usernameAuthenticator.authenticate.token
      })
    },
    loading,
    error,
  }
}
