import { SvgIcon } from '@mui/material'

export const UserIcon = () => {
  return (
    <SvgIcon viewBox="0 0 210 211" sx={{ width: '210px', height: '211px' }}>
      <path
        opacity="0.1"
        d="M105 210C162.99 210 210 162.99 210 105C210 47.0101 162.99 0 105 0C47.0101 0 0 47.0101 0 105C0 162.99 47.0101 210 105 210Z"
        fill="#B455A0"
      />
      <path
        d="M105 112.52C125.488 112.52 142.096 95.9114 142.096 75.4239C142.096 54.9365 125.488 38.3281 105 38.3281C84.5127 38.3281 67.9043 54.9365 67.9043 75.4239C67.9043 95.9114 84.5127 112.52 105 112.52Z"
        fill="#B455A0"
      />
      <path
        d="M105 112.52C69.0612 112.52 39.9253 141.656 39.9253 177.595C39.9253 181.16 40.2145 184.655 40.7678 188.063C58.5235 201.814 80.8061 210.006 105 210.006C129.194 210.006 151.477 201.82 169.232 188.063C169.786 184.655 170.075 181.16 170.075 177.595C170.075 141.656 140.939 112.52 105 112.52Z"
        fill="#B455A0"
      />
    </SvgIcon>
  )
}
