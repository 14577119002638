import { useSvgParams } from '../../hooks'
import { SvgIcon, useTheme } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const ArrowBackIcon = ({ color, size, sx, accentColor = 'accent' }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })
  const theme = useTheme()

  return (
    <SvgIcon viewBox="0 0 55 55" fill="none" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        d="M27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 42.6878 12.3122 55 27.5 55Z"
        fill={fill}
      />
      <path
        d="M35 27.75H20M20 27.75L26.75 34.5M20 27.75L26.75 21"
        stroke={theme.palette[accentColor].main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
