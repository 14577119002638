import { Theme, Typography, useTheme } from '@mui/material'
import { CommentItem } from '../../interfaces'
import { SystemStyleObject } from '@mui/system'
import { CommentWrap } from '../CommentWrap'
import { DateCell } from '../DateCell'

interface CommentProps {
  comment: CommentItem
  inline?: boolean
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
}

export const Comment = ({ comment, inline, sx }: CommentProps) => {
  const theme = useTheme()

  return (
    <CommentWrap inline={inline} sx={sx}>
      <Typography sx={{ color: theme.palette.grey[600] }}>
        <DateCell date={comment.createdAt} />
      </Typography>
      <Typography sx={{ mt: 1 }}>{comment.content}</Typography>
    </CommentWrap>
  )
}
