import { OffersIcon, EventIcon, ProfileIcon, SummaryIcon } from '../icons'

export const shortcuts = [
  {
    to: '/offers',
    label: 'Piedāvājumi',
    iconType: <OffersIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/offers/utilizations',
    label: 'Atskaites',
    iconType: <SummaryIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/events',
    label: 'Pasākumi',
    iconType: <EventIcon size={'medium'} color={'primary'} />,
  },
  {
    to: '/profile',
    label: 'Profils',
    iconType: <ProfileIcon size={'medium'} color={'primary'} />,
  },
]
