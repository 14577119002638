export const FLEX_DIRECTION_COLUMN = {
  display: 'flex',
  flexDirection: 'column',
}

export const background = 'linear-gradient(165.06deg, #8E5BA6 2.43%, #B455A0 99.44%)'

export const BACKGROUND_CIRCLE = {
  width: '1546px',
  height: '1546px',
  background: 'linear-gradient(154.44deg, #8E5BA6 63.91%, #B455A0 86.84%)',
  borderRadius: '50%',
  left: '-1199px',
  top: '-747px',
  position: 'absolute',
  content: '""',
}

export const FILTER_BUTTON_STYLE = {
  borderRadius: '3px!important',
  minWidth: '90px',
  width: '100%',
  borderStyle: 'none !important',
}
