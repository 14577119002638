import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { BoolObject, StringObject, Tag } from '../../interfaces'

interface TagSelectProps {
  selectedTags: string[]
  name: string
  tags: Tag[]
  disabled?: boolean
  limit?: number
  disabledTags?: string[]
  error?: string
  loading?: boolean
  onChange: (name: string, selectedTags: string[]) => void
  onDelete: (name: string, index: number) => void
}

export const TagSelect = ({
  selectedTags,
  name,
  tags,
  disabled,
  limit,
  disabledTags,
  error,
  loading,
  onChange,
  onDelete,
}: TagSelectProps) => {
  const [tagLabels, setTagLabels] = useState<StringObject>({})
  const [selectedMap, setSelectedMap] = useState<BoolObject>({})
  const [disabledMap, setDisabledMap] = useState<BoolObject>({})
  const [reachedLimit, setReachedLimit] = useState(false)

  useEffect(() => {
    const nextTagLabels: StringObject = {}

    for (const tag of tags) {
      nextTagLabels[tag.id] = tag.title
    }

    setTagLabels(nextTagLabels)
  }, [tags])

  useEffect(() => {
    const nextSelectedMap: BoolObject = {}

    for (const tag of selectedTags) {
      nextSelectedMap[tag] = true
    }

    setSelectedMap(nextSelectedMap)

    if (limit) {
      setReachedLimit(selectedTags.length >= limit)
    }
  }, [limit, selectedTags])

  useEffect(() => {
    const nextDisabledMap: BoolObject = {}

    if (disabledTags) {
      for (const tag of disabledTags) {
        nextDisabledMap[tag] = true
      }
    }

    setDisabledMap(nextDisabledMap)
  }, [disabledTags])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    onChange(name, typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <FormControl fullWidth>
      <Select
        error={!!error}
        notched
        value={selectedTags}
        multiple
        onChange={handleChange}
        labelId={name}
        placeholder={loading ? 'Ielādē datus...' : tags.length ? 'Nav neviena ieraksta' : undefined}
        disabled={disabled}
        sx={{ '.MuiSelect-select.MuiSelect-outlined': { minHeight: '24px' } }}
        renderValue={(selected) => (
          <Stack direction="row" spacing={1}>
            {selected.map((item, index) => (
              <Chip
                size="small"
                disabled={disabled}
                key={index}
                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                label={tagLabels[item]}
                color="primary"
                onDelete={() => onDelete(name, index)}
              />
            ))}
          </Stack>
        )}
      >
        {tags.length > 0 ? (
          tags.map((tag, index) => (
            <MenuItem key={index} value={tag.id} disabled={(reachedLimit && !selectedMap[tag.id]) || disabledMap[tag.id]}>
              <Checkbox checked={selectedMap[tag.id] || false} />
              <ListItemText primary={tag.title} />
            </MenuItem>
          ))
        ) : (
          <MenuItem value={0} disabled={true}>
            <ListItemText primary={loading ? 'Ielādē datus...' : 'Nav neviena ieraksta'} />
          </MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText error sx={{ mt: '3px', ml: '14px' }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
