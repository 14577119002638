import { ApolloClient, ApolloLink, ApolloProvider as Provider, createHttpLink, InMemoryCache } from '@apollo/client'
import { useUserContext } from '../../store'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ErrorCodes } from '../../enums'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { GRAPHQL_API_URL } from '../../constants'

const useClient = (token: string | undefined, apiUri: string, navigate: NavigateFunction) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Token ${token}` : '',
      },
    }
  })

  // TODO: finalize error handling when requirements are clarified
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)

        if (extensions && extensions.Code) {
          switch (extensions.Code) {
            case ErrorCodes.AuthorizationError:
              navigate('/login', { replace: true })
              break
            case ErrorCodes.GenericError:
              navigate('/error', { replace: true })
              break
          }
        }
      })

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      navigate('/error', { replace: true })
    }
  })

  const httpLink = createHttpLink({
    uri: apiUri,
  })

  // TODO: look into persistent cache https://github.com/apollographql/apollo-cache-persist to provide offline capability
  return new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  })
}

interface ApolloProviderProps {
  children: JSX.Element
}

export const ApolloProvider = ({ children }: ApolloProviderProps) => {
  const navigate = useNavigate()
  const token = useUserContext((state) => state.token)

  const client = useClient(token, GRAPHQL_API_URL, navigate)

  return <Provider client={client}>{children}</Provider>
}
