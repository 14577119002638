import {
  EventItem,
  EventItemAdd,
  EventItemEdit,
  EventsHome,
  FatalError,
  Home,
  Login,
  Logout,
  Offers,
  Profile,
  ResultError,
  ResultOffer,
  ResultOffers,
  ResultSuccess,
  Scanner,
  Toc,
  Users,
  Verify,
  ChangePassword,
} from './views'
import { RouteObject } from 'react-router-dom'
import { AuthGuard, Layout } from './containers'

export const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/offers/*',
        element: <Offers />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/users/*',
        element: <Users />,
      },
      {
        path: '/scanner',
        element: <Scanner />,
      },
      {
        path: '/verify',
        element: <Verify />,
      },
      {
        path: '/result/error',
        element: <ResultError />,
      },
      {
        path: '/result/success/offers',
        element: <ResultOffers />,
      },
      {
        path: '/result/success/offer',
        element: <ResultOffer />,
      },
      {
        path: '/result/success/utilized',
        element: <ResultSuccess />,
      },
      {
        path: '/events',
        element: <EventsHome />,
      },
      {
        path: '/events/new',
        element: <EventItemAdd />,
      },
      {
        path: '/events/edit/:id',
        element: <EventItemEdit />,
      },
      {
        path: '/events/item/:id',
        element: <EventItem />,
      },
      {
        path: '/change-password',
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: '/error',
    element: <FatalError />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/toc',
    element: <Toc />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
]
