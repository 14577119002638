import { Box, Button, Link, Typography, useMediaQuery } from '@mui/material'
import { REGISTER_SEND_TO_EMAIL, VIEWPORT_MD } from '../../../constants'

interface RegisterProps {
  onBack: () => void
}

export const Register = ({ onBack }: RegisterProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)
  const width = matches ? '35%' : '95%'
  const onBackClick = () => {
    onBack()
  }

  return (
    <Box
      sx={{
        border: 'thin solid',
        borderRadius: '6px',
        color: 'white',
        position: 'relative',
        width,
        pb: 2,
        pt: 2,
        mr: 'auto',
        ml: 'auto',
        overflowWrap: 'normal',
      }}
    >
      <Box sx={{ mb: '16px' }}>
        <Typography component="span" color="accent.main" sx={{ fontSize: '24px', pl: 2, pr: 2 }}>
          Reģistrēt lietotāju
        </Typography>
      </Box>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Typography component="span" color="accent.main">
          Lai reģistrētu lietotāju sūti e-pastu uz{' '}
          <Link color="accent.main" type="email" href={'mailto:' + REGISTER_SEND_TO_EMAIL}>
            {REGISTER_SEND_TO_EMAIL}
          </Link>{' '}
          norādot uzņēmuma nosaukumu, reģistrācijas numuru un kontaktinformāciju
        </Typography>
      </Box>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Button
          sx={{ mt: 4, width: '100%', color: 'accent.main' }}
          size="large"
          variant="contained"
          onClick={onBackClick}
          color="secondary"
        >
          Atpakaļ
        </Button>
      </Box>
    </Box>
  )
}
