import { AttachmentOutputLyl, GetOfferQuery, MessageOutputLyl } from '../../graphql'
import { OfferEntry } from '../../interfaces'
import { mapAttachments, mapComments, parseDateTime, getTimeInterval } from '..'
import { ALL_POPULATION_GROUPS } from '../../constants'

export const mapOfferItem = (item: GetOfferQuery): OfferEntry | null => {
  try {
    const offer = item.loyalty.getOffer
    const groups = offer.groups?.map((group, index) => group?.name || `Grupa ${index}`) || []
    const sortedGroups = groups.sort((a, b) => a.localeCompare(b))

    return {
      id: offer.id,
      parentId: offer.message.id,
      title: offer.message.title?.all.lv,
      content: offer.message.content?.all.lv,
      createdAt: parseDateTime(offer.submittedAt),
      approvedAt: parseDateTime(offer.municipalityApproval[0].approvedAt),
      validityInterval: getTimeInterval(offer.startsAt, offer.endsAt),
      comments: mapComments(offer.message.replies as MessageOutputLyl[]),
      images: mapAttachments(offer.message.attachments as AttachmentOutputLyl[]),
      groups: sortedGroups.length ? sortedGroups : [ALL_POPULATION_GROUPS],
    }
  } catch {
    return null
  }
}
