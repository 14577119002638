import { Box, Theme, useMediaQuery } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { VIEWPORT_MD } from '../../constants'

interface StickyFooterProps {
  children: JSX.Element | JSX.Element[]
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
}

export const StickyFooter = ({ children, sx }: StickyFooterProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)

  const flexDirection = matches ? 'column' : 'row'
  const alignItems = matches ? 'center' : 'start'
  const justifyContent = matches ? 'space-between' : 'space-around'
  return (
    <Box
      sx={[
        {
          flexDirection,
          display: 'flex',
          position: 'fixed',
          alignItems,
          p: 1,
          justifyContent,
          zIndex: 2,
          bottom: '10px',
          left: 'env(safe-area-inset-left, 0)',
          right: 'env(safe-area-inset-right, 0)',
          mt: 2,
        },
        sx || {},
      ]}
    >
      {children}
    </Box>
  )
}
