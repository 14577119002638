import create from 'zustand'

interface UserContextStore {
  token: string
  username: string
  setToken: (nextToken: string) => void
  setUsername: (nextUsername: string) => void
  resetUserContext: () => void
}

export const useUserContext = create<UserContextStore>((set) => ({
  token: '',
  username: '',
  setToken: (nextToken: string) => {
    set(() => ({ token: nextToken }))
  },
  setUsername: (nextUsername: string) => {
    set(() => ({ username: nextUsername }))
  },
  resetUserContext: () => {
    set(() => ({ token: '', username: '' }))
  },
}))
