import { useState, useEffect, useRef } from 'react'
import { InfiniteList, StickyFooter, FooterButton, UtilizationsFilters, BackButton } from '../../../components'
import { Box, Typography, Divider, Chip, useTheme, SelectChangeEvent, ListItemButton, useMediaQuery } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { startOfWeek, endOfWeek } from 'date-fns'
import { AggregatedUtilizationsItem, Municipality, FilterState } from '../../../interfaces'
import {
  mapAggregatedUtilizations,
  mapMunicipalities,
  getDateRange,
  xlsxExporter,
  xlsxMobileExporter,
  updateSearchParams,
  getSelectedRange,
} from '../../../utils'
import { DEFAULT_ENTRIES_PER_PAGE, LOCALE_STRING_FORMAT, VIEWPORT_MD, today } from '../../../constants'
import { useGetAggregatedOffersQuery, useGetMunicipalitiesQuery } from '../../../graphql'
import { RangeFilter } from '../../../enums'
import { useSnackbarStore } from '../../../store'
import { Capacitor } from '@capacitor/core'

export const UtilizationAggregationsList = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const matches = useMediaQuery(VIEWPORT_MD)
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const [currentPage, setCurrentPage] = useState(1)

  const [filters, setFilters] = useState<FilterState>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    selectedRange: RangeFilter.NONE,
  })

  const [municipalities, setMunicipalities] = useState<Municipality[]>([])
  const [searchParams] = useSearchParams()
  const [downloading, setDownloading] = useState(false)

  const { data: municipalitiesData, loading: loadingMunicipalities } = useGetMunicipalitiesQuery()
  const { data, loading } = useGetAggregatedOffersQuery({
    variables: {
      filter: {
        temporal: { from: filters.startDate?.toISOString(), to: filters.endDate?.toISOString() },
        municipalityId: filters.municipalityId as string,
      },
    },
  })
  const aggregationsList = useRef<AggregatedUtilizationsItem[]>([])
  const [pagedAggregationsList, setPagedAggregationsList] = useState<AggregatedUtilizationsItem[]>([])

  const width = matches ? '576px' : '100%'
  const mobile = Capacitor.getPlatform() !== 'web'
  const backgroundColor = mobile ? theme.palette.secondary.main : '#414042'

  useEffect(() => {
    updateSearchParams(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    const startParam = searchParams.get('start')
    const endParam = searchParams.get('end')
    const municipalityIdParam = searchParams.get('munId')

    setFilters({
      ...filters,
      startDate: startParam ? new Date(startParam) : filters.startDate,
      endDate: endParam ? new Date(endParam) : filters.endDate,
      municipalityId: municipalityIdParam ? municipalityIdParam : undefined,
      selectedRange: startParam && endParam ? getSelectedRange(startParam, endParam) : RangeFilter.CURRENT_WEEK,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (municipalitiesData) {
      setMunicipalities(mapMunicipalities(municipalitiesData))
    }
  }, [municipalitiesData])

  useEffect(() => {
    if (data) {
      const list = mapAggregatedUtilizations(data)

      aggregationsList.current = list
      setCurrentPage(1)
      setPagedAggregationsList(list.slice(0, DEFAULT_ENTRIES_PER_PAGE))
    }
  }, [data])

  const createQueryString = (params: { [key: string]: any }): string => {
    let queryString = Object.entries(params)
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    return queryString
  }

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setFilters({
      ...filters,
      startDate: start,
      endDate: end,
      selectedRange: RangeFilter.NONE,
    })
  }

  const onRangeChange = (event: React.MouseEvent<HTMLElement>, range: RangeFilter) => {
    const { start, end } = getDateRange(range)
    setFilters({
      ...filters,
      selectedRange: range,
      startDate: start,
      endDate: end,
    })
  }

  const onMunicipalityChange = (event: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      municipalityId: event.target.value,
    })
  }

  const onClear = () => {
    setFilters({
      ...filters,
      municipalityId: undefined,
    })
  }

  const onLoadMore = () => {
    const nextPage = currentPage + 1
    const nextEntriesCount = nextPage * DEFAULT_ENTRIES_PER_PAGE

    setCurrentPage(nextPage)

    if (nextEntriesCount >= aggregationsList.current.length) {
      setPagedAggregationsList(aggregationsList.current)
      return
    }

    setPagedAggregationsList(aggregationsList.current.slice(0, nextEntriesCount))
  }

  const onXlsxExport = () => {
    // Column titles and data for xlsx file to export, first column width and file name
    const columnTitles = ['Piedāvājuma nosaukums', 'Utilizāciju skaits']
    const exportData = pagedAggregationsList.map(({ title, utilizationCount }) => [title, utilizationCount.toString()])
    const maxWidth = pagedAggregationsList.reduce((w, r) => Math.max(w, r.title.length), 10)
    const fileName = `Atskaite ${
      filters.startDate ? filters.startDate.toLocaleDateString('lv-LV', LOCALE_STRING_FORMAT) : 'sākuma datums'
    }-${
      filters.endDate
        ? filters.endDate.toLocaleDateString('lv-LV', LOCALE_STRING_FORMAT)
        : today.toLocaleDateString('lv-LV', LOCALE_STRING_FORMAT)
    }`

    if (mobile) {
      xlsxMobileExporter(exportData, fileName, maxWidth, columnTitles, setDownloading, showSnackbar)
    } else {
      xlsxExporter(exportData, fileName, maxWidth, columnTitles, setDownloading, showSnackbar)
    }
  }

  const renderListItem = (utilizedItem: AggregatedUtilizationsItem) => {
    const parameters = {
      start: filters.startDate?.toISOString(),
      end: filters.endDate?.toISOString(),
      munId: filters.municipalityId,
    }
    return (
      <ListItemButton
        divider
        onClick={() => navigate(`/offers/utilizations/${utilizedItem.id}?${createQueryString(parameters)}`)}
        sx={{ display: 'flex', flexDirection: 'column', padding: 0 }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
        >
          <Typography variant="h4" sx={{ mb: 2, mt: 2, width: '80%' }}>
            {utilizedItem.title}
          </Typography>
          <Chip
            color="secondary"
            sx={{ borderRadius: 2, width: '64px', color: theme.palette.accent.main, fontSize: '1rem' }}
            label={utilizedItem.utilizationCount}
          />
        </Box>
      </ListItemButton>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <UtilizationsFilters
        filters={filters}
        municipalities={municipalities}
        loading={loading || downloading || loadingMunicipalities}
        onMunicipalityChange={onMunicipalityChange}
        onClearMunicipality={onClear}
        onRangeChange={onRangeChange}
        onDateChange={onDateChange}
      />
      <Divider sx={{ width: '100%' }} />
      <InfiniteList
        loading={loading}
        renderFn={renderListItem}
        onLoadMore={onLoadMore}
        list={pagedAggregationsList}
        width={width}
      />
      <StickyFooter>
        <FooterButton
          disabled={loading || downloading || loadingMunicipalities}
          onClick={onXlsxExport}
          buttonLabel="Saglabāt atskaiti"
          sx={{ '&:hover': { backgroundColor } }}
        ></FooterButton>
        {!matches ? <BackButton /> : <></>}
      </StickyFooter>
    </Box>
  )
}
