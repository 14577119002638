import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/*
 * upon navigation, scrolls back to the top
 * for additional details, please refer to https://stackoverflow.com/a/71753162/2902063
 */
export const ScrollToTopRestoration = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history

    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    window.scrollTo(0, 0)
  }, [pathname])

  return <></>
}
