import React from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, Link, Typography } from '@mui/material'

interface TocDisclaimerProps {
  acceptedToc: boolean
  disabled: boolean
  onOpenTerms: () => void
  onTocDisclaimer: (hasAcceptedToc: boolean) => void
}

export const TocDisclaimer = ({ acceptedToc, disabled, onOpenTerms, onTocDisclaimer }: TocDisclaimerProps) => {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    if (disabled) {
      return
    }

    onOpenTerms()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTocDisclaimer(event.target.checked)
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox checked={acceptedToc} disabled={disabled} color="secondary" onChange={onChange} sx={{ color: 'white' }} />
        }
        label={
          <Box>
            <Typography component="span" color="accent.main">
              Atzīmējot, Jūs piekrītat lietotnes{' '}
            </Typography>
            <Link
              sx={{
                color: 'white',
                textDecorationColor: 'white',
              }}
              href="#"
              onClick={onClick}
            >
              lietošanas noteikumiem
            </Link>
            <Typography component="span" color="accent.main">
              , privātuma politikai un notifikāciju paziņojumiem.
            </Typography>
          </Box>
        }
      />
    </FormGroup>
  )
}
