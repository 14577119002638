import { StringObject } from '../interfaces'

export const ACCEPTED_FILE_TYPES: StringObject = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  webp: 'image/webp',
}

const acceptedUploadFiles = Object.keys(ACCEPTED_FILE_TYPES)

export const ACCEPTED_UPLOAD_FILE_TYPES = acceptedUploadFiles.map((type) => `.${type}`).join(',')

const lastType = acceptedUploadFiles.pop()

export const ACCEPTED_FILE_TYPES_TEXT = acceptedUploadFiles.join(', ') + ` vai ${lastType}`

export const FILE_SIZE_LIMIT_MB = 1
