import { Alert, List, ListItem } from '@mui/material'
import { useInView } from 'react-cool-inview'
import { DelayedLoader } from '../DelayedLoader'

interface InfiniteListProps<T> {
  loading?: boolean
  list?: T[]
  renderFn: (listItem: T, index: number) => JSX.Element
  onLoadMore: () => void
  width?: string
}

export const InfiniteList = <T extends unknown>({
  loading,
  list,
  renderFn,
  onLoadMore,
  width = '100%',
}: InfiniteListProps<T>) => {
  const { observe } = useInView({
    rootMargin: '50px',
    onEnter: ({ unobserve }) => {
      unobserve()
      onLoadMore()
    },
  })

  if (loading) {
    return <DelayedLoader />
  }

  return (
    <>
      {list ? (
        list.length > 0 ? (
          <List sx={{ pb: '45px', pt: 0, display: 'flex', justifyContent: 'center', flexFlow: 'column', width }}>
            {list.map((item: any, index: number) => (
              <ListItem key={index} disablePadding ref={index === list.length - 1 ? observe : null}>
                {renderFn(item, index)}
              </ListItem>
            ))}
          </List>
        ) : (
          <>
            <Alert sx={{ width, pr: 0, pl: 1 }} severity="info">
              Nav neviena ieraksta
            </Alert>
          </>
        )
      ) : (
        <></>
      )}
    </>
  )
}
