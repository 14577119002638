import React, { useState, FormEvent } from 'react'
import { Box, Typography, TextField, Button, Link, Theme, useMediaQuery } from '@mui/material'
import { FLEX_DIRECTION_COLUMN } from '../../../style/sx'
import { DelayedLoader } from '../../DelayedLoader'
import { TogglePasswordVisibility } from '../../TogglePasswordVisibility'
import { SystemStyleObject } from '@mui/system'
import { VIEWPORT_MD } from '../../../constants'
import { PasswordInputType } from '../../../interfaces'

interface UsernameAuthProps {
  children: JSX.Element
  loading: boolean
  onLogin: (uesrname: string, password: string) => void
  onRegister: () => void
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
}

export const UsernameAuth = ({ children, loading, onLogin, onRegister, sx }: UsernameAuthProps) => {
  const [usernameField, setUsernameField] = useState('')
  const [passwordField, setPasswordField] = useState('')
  const matches = useMediaQuery(VIEWPORT_MD)
  const screenMatches = useMediaQuery('(min-width:980px)')
  const [type, setType] = useState<PasswordInputType>('password')

  const width = screenMatches ? '22%' : matches ? '45%' : '95%'

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameField(event.target.value)
  }
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordField(event.target.value)
  }
  const handleShowPassword = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    onLogin(usernameField, passwordField)
  }

  const onRegisterBtnClick = () => {
    onRegister()
  }

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={[FLEX_DIRECTION_COLUMN, { mr: 'auto', ml: 'auto', mb: 14, width, position: 'relative' }, sx || {}]}
      noValidate
    >
      <Typography color="accent.main" sx={{ fontSize: '35px', fontWeight: 500, mb: 4, pr: 4 }}>
        Pašvaldības lojalitātes
      </Typography>
      <Box component="label" sx={FLEX_DIRECTION_COLUMN}>
        <Typography component="span" color="accent.main" sx={{ mb: 1 }}>
          Lietotājvārds
        </Typography>
        <TextField onChange={onChangeUsername} type="email" disabled={loading} value={usernameField} />
      </Box>
      <Box component="label" sx={FLEX_DIRECTION_COLUMN}>
        <Typography component="span" color="accent.main" sx={{ mt: 1, mb: 1 }}>
          Parole
        </Typography>
        <TextField
          type={type}
          disabled={loading}
          value={passwordField}
          onChange={onChangePassword}
          InputProps={{
            endAdornment: <TogglePasswordVisibility onShowPassword={handleShowPassword} type={type} />,
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>{children}</Box>
      <Button
        sx={{ mt: 4, color: 'accent.main' }}
        size="large"
        variant="contained"
        disabled={loading}
        type="submit"
        color="secondary"
      >
        {loading ? <DelayedLoader loaderSize={26.5} /> : <>Pieslēgties</>}
      </Button>
      <Link color="accent.main" href="#" onClick={onRegisterBtnClick} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        {loading ? <DelayedLoader loaderSize={26.5} /> : <>Reģistrēties</>}
      </Link>
    </Box>
  )
}
