import {
  DATE_FROM_BEFORE_TO,
  DATE_TIME_IN_PAST,
  INVALID_DATE_TIME_FORMAT,
  REQUIRED_ERROR_MESSAGE,
  REQUIRED_MUNICIPALITIES,
} from '../../constants'
import { OfferNew, StringObject } from '../../interfaces'
import { getISOString } from '../timeUtils'

export const validateOfferForm = (offerItem: OfferNew): [boolean, StringObject, string | null, string | null] => {
  const errors: StringObject = {}
  let errorCount = 0

  const startsAt = getISOString(offerItem.startsAt)
  const endsAt = getISOString(offerItem.endsAt)

  if (startsAt == null) {
    errors['startsAt'] = INVALID_DATE_TIME_FORMAT
  } else if (!startsAt) {
    errors['startsAt'] = REQUIRED_ERROR_MESSAGE
  }

  if (endsAt == null) {
    errors['endsAt'] = INVALID_DATE_TIME_FORMAT
  } else if (!endsAt) {
    errors['endsAt'] = REQUIRED_ERROR_MESSAGE
  }

  if (offerItem.startsAt instanceof Date) {
    if (offerItem.startsAt.getTime() < Date.now()) {
      errors['startsAt'] = DATE_TIME_IN_PAST
    }
  }

  if (offerItem.startsAt instanceof Date && offerItem.endsAt instanceof Date) {
    if (offerItem.startsAt.getTime() > offerItem.endsAt.getTime()) {
      errors['startsAt'] = DATE_FROM_BEFORE_TO
    }
  }

  if (!offerItem.title) {
    errors['title'] = REQUIRED_ERROR_MESSAGE
  }

  if (offerItem.municipalitiesIds.length === 0) {
    errors['municipalities'] = REQUIRED_MUNICIPALITIES
  }

  const isFormValid = Object.keys(errors).length + errorCount === 0

  return [isFormValid, errors, startsAt, endsAt]
}
