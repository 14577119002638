import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ItemButton, InfiniteList, StickyFooter, FooterButton, BackButton, FilterToggleButtons } from '../../../components'
import { useDeleteOfferMutation, useGetOffersQuery, OffersTemporalFilterOptionLyl } from '../../../graphql'
import { OfferListItem } from '../../../interfaces'
import { mapOfferList } from '../../../utils'
import { DEFAULT_ENTRIES_PER_PAGE, VIEWPORT_MD } from '../../../constants'
import { useConfirmStore } from '../../../store'
import AddIcon from '@mui/icons-material/Add'
import { Box, useMediaQuery } from '@mui/material'

export const OffersHome = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const matches = useMediaQuery(VIEWPORT_MD)

  const [filter, setFilter] = useState(OffersTemporalFilterOptionLyl.Unexpired)
  const { data, loading, refetch } = useGetOffersQuery({ variables: { filter: { temporal: filter } } })
  const [offerList, setOfferList] = useState<OfferListItem[]>([])
  const [pagedOfferList, setPagedOfferList] = useState<OfferListItem[]>([])
  const [deleteOffer] = useDeleteOfferMutation()
  const confirm = useConfirmStore((state) => state.confirm)

  useEffect(() => {
    if (data) {
      const list = mapOfferList(data)

      setOfferList(list)
      setPagedOfferList(list.slice(0, DEFAULT_ENTRIES_PER_PAGE))
    }
  }, [data])

  const onNewOffer = () => {
    navigate('/offers/new')
  }

  const onEdit = (id: string) => {
    navigate('/offers/edit/' + id)
  }

  const onView = (id: string) => {
    navigate('/offers/item/' + id)
  }

  const onFilterChange = (event: React.MouseEvent<HTMLElement>, value: OffersTemporalFilterOptionLyl) => {
    if (value !== null) {
      setFilter(value)
    }
  }

  const onDelete = async (id: string) => {
    const response = await confirm({
      promptTitle: 'Vai tiešām dzēst šo piedāvājumu?',
      confirmButtonColor: 'error',
      confirmButtonLabel: 'Dzēst',
    })

    if (response && id) {
      deleteOffer({ variables: { id: id } }).then(() => {
        refetch()
      })
    }
  }

  const onLoadMore = () => {
    const nextPage = currentPage + 1
    const nextEntriesCount = nextPage * DEFAULT_ENTRIES_PER_PAGE

    setCurrentPage(nextPage)

    if (nextEntriesCount >= offerList.length) {
      setPagedOfferList(offerList)
      return
    }

    setPagedOfferList(offerList.slice(0, nextEntriesCount))
  }

  const renderListItem = (offerItem: OfferListItem) => {
    return (
      <ItemButton
        title={offerItem.title}
        id={offerItem.id}
        preview={offerItem.preview}
        disabled={offerItem.approvedAt !== null}
        onView={() => onView(offerItem.id)}
        onEdit={() => onEdit(offerItem.id)}
        onDelete={() => onDelete(offerItem.id)}
        validityInterval={offerItem.validityInterval}
        approvedAt={offerItem.approvedAt !== null}
      />
    )
  }

  return (
    <>
      <StickyFooter>
        <FooterButton buttonLabel="Pievienot jaunu" onClick={onNewOffer}>
          <AddIcon />
        </FooterButton>
        {!matches ? <BackButton /> : <></>}
      </StickyFooter>
      <FilterToggleButtons
        onChange={onFilterChange}
        value={filter}
        disabled={loading}
        filterEnum={OffersTemporalFilterOptionLyl}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <InfiniteList
          width={matches ? '80%' : '100%'}
          loading={loading}
          renderFn={renderListItem}
          onLoadMore={onLoadMore}
          list={pagedOfferList}
        />
      </Box>
    </>
  )
}
