import React, { ErrorInfo } from 'react'
import { Navigate } from 'react-router-dom'

interface ErrorBoundaryProps {
  children: JSX.Element | JSX.Element[]
}

interface ErrorBoundaryState {
  error: Error | null
  errorInfo: ErrorInfo | null
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: should log error

    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      return <Navigate to="/error" />
    }

    return this.props.children
  }
}
