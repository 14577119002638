import { Grid, Typography } from '@mui/material'

interface RowProps {
  label: string
  children: JSX.Element | JSX.Element[]
}

export const Row = ({ label, children }: RowProps) => {
  return (
    <Grid container spacing={2} sx={{ alignItems: 'center', minHeight: '52px' }}>
      <Grid item xs={4}>
        <Typography component="span">{label}</Typography>
      </Grid>
      <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {children}
      </Grid>
    </Grid>
  )
}
