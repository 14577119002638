import { useNavigate } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { App } from '@capacitor/app'
import { IconButton } from '@mui/material'
import { ArrowBackIcon } from '../../icons'
import { useDetectKeyboard } from '../../hooks'
interface BackButtonProps {
  color?: 'primary' | 'secondary' | 'accent'
  accentColor?: 'primary' | 'secondary' | 'accent'
}

export const BackButton = ({ color = 'primary', accentColor = 'accent' }: BackButtonProps) => {
  const navigate = useNavigate()
  const isKeyboardOpen = useDetectKeyboard()
  const backPressedTimes = useRef(0)
  const timerId = useRef(0)

  const onClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    App.addListener('backButton', () => {
      backPressedTimes.current++

      if (backPressedTimes.current === 2) {
        App.exitApp()
      }

      if (timerId.current) {
        clearTimeout(timerId.current)
      }

      timerId.current = window.setTimeout(() => {
        backPressedTimes.current--

        navigate(-1)
      }, 200)

      return () => {
        if (timerId) {
          clearTimeout(timerId.current)
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isKeyboardOpen) {
    return <></>
  }

  return (
    <>
      <IconButton
        sx={{
          p: 0,
          width: '42.25px',
          height: '42.25px',
          boxSizing: 'content-box',
          boxShadow: '0 2px 5px 1px rgba(88,89,91,0.75)',
        }}
        size="large"
        onClick={onClick}
      >
        <ArrowBackIcon size="custom" color={color} accentColor={accentColor} />
      </IconButton>
    </>
  )
}
