import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const SummaryIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 20 20" sx={[{ width: 'auto', height: dimension }, sx || {}]}>
      <path
        fill="none"
        d="M13.8864 19V10M13.8864 10L17.108 13.375M13.8864 10L10.6648 13.375M1 12.25L9.59091 3.25L12.8125 6.625L18.1818 1"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
