import { Box, List, ListItem, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import { FooterButton, StickyFooter } from '../../components'
import { Link, useNavigate } from 'react-router-dom'
import { shortcuts, VIEWPORT_MD } from '../../constants'
import { Capacitor } from '@capacitor/core'
import { useRef } from 'react'
import { QrIcon } from '../../icons'

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.95rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
}))

export const Home = () => {
  const navigate = useNavigate()

  const onScanner = () => {
    navigate('/scanner')
  }

  const theme = useTheme()
  const platform = useRef(Capacitor.getPlatform())
  const matches = useMediaQuery(VIEWPORT_MD)
  const flexWrap = matches ? 'nowrap' : 'wrap'

  return (
    <Box
      sx={{
        position: 'absolute',
        height: 'calc(100vh - 72px - env(safe-area-inset-top, 0))',
        width: '100vw',
        top: 'calc(72px + env(safe-area-inset-top, 0))',
        right: 0,
        overflow: 'hidden',
        ':before': {
          width: '1057px',
          height: '1057px',
          background: 'linear-gradient(150.67deg, #B455A0 11.53%, #8E5BA6 49.44%)',
          borderRadius: '50%',
          left: '-22.36%',
          right: '-171.25%',
          top: '27.38%',
          bottom: '-59.54%',
          position: 'absolute',
          content: '""',
        },
      }}
    >
      <List
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: '20vh',
          display: 'flex',
          flexDirection: 'row',
          flexWrap,
          zIndex: 2,
          '>li:nth-child(odd)': {
            pr: 1,
          },
          '>li:nth-child(even)': {
            pl: 1,
          },
        }}
      >
        {shortcuts?.map((item, index) => (
          <ListItem key={index} sx={{ width: '50%' }}>
            <Link
              style={{
                textDecoration: 'none',
                backgroundColor: theme.palette.accent.main,
                width: '100%',
                height: '118px',
                minHeight: '53px',
                borderRadius: '6px',
                marginTop: 4,
                marginBottom: 4,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'uppercase',
                border: 'solid #B455A0 1px',
                flexDirection: 'column',
              }}
              to={item.to}
            >
              <Box sx={{ mt: 3 }}>{item.iconType}</Box>
              <ResponsiveTypography color="secondary.dark">{item.label}</ResponsiveTypography>
            </Link>
          </ListItem>
        ))}
      </List>
      {platform.current !== 'web' && (
        <StickyFooter>
          <FooterButton buttonLabel="Skenēt QR kodu" onClick={onScanner} fullWidth>
            <QrIcon color="accent" size="small" />
          </FooterButton>
        </StickyFooter>
      )}
    </Box>
  )
}
