import { Chip, Stack, Theme } from '@mui/material'
import { SxProps } from '@mui/system'

interface GroupListProps {
  groups: string[]
  direction?: 'row' | 'column'
  groupLabels?: { [key: string]: string }
  sx?: SxProps<Theme>
}

export const GroupList = ({ groups, direction = 'row', groupLabels, sx }: GroupListProps) => {
  return (
    <Stack direction={direction} spacing={1}>
      {groups.map((group) => (
        <Chip sx={sx} key={group} label={(groupLabels && groupLabels[group]) || group} variant="outlined" />
      ))}
    </Stack>
  )
}
