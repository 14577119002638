import { TagOutputPev } from '../graphql'
import { StringObject, Tag } from '../interfaces'

export const mapTagsForEntry = (tags: TagOutputPev[], type: string): [string[], string[], StringObject] => {
  const typeTags: string[] = []
  const locationTags: string[] = []
  const tagMap: StringObject = {}

  try {
    for (const tag of tags) {
      tagMap[tag.id] = tag.title.all.lv

      if (tag.groupKey === type) {
        typeTags.push(tag.id)
      } else {
        locationTags.push(tag.id)
      }
    }
  } catch {}

  return [typeTags, locationTags, tagMap]
}

export const mapTags = (tags: TagOutputPev[], type: string) => {
  const typeTags: Tag[] = []
  const locationTags: Tag[] = []

  try {
    for (const tag of tags) {
      const newTag = {
        id: tag.id,
        groupKey: tag.groupKey,
        title: tag.title.all.lv,
      }

      if (tag.groupKey === type) {
        typeTags.push(newTag)
      } else {
        locationTags.push(newTag)
      }
    }
  } catch {}

  return [typeTags, locationTags]
}
