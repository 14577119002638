import { Box } from '@mui/system'
import styled from '@emotion/styled'

export const FullHeightFlexCenterWrap = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))
