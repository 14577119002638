import { attributesToProps, DOMNode, Element } from 'html-react-parser'

export const htmlParseOptions = {
  replace: (domNode: DOMNode) => {
    if (domNode.type === 'tag' && domNode instanceof Element && domNode.name === 'a') {
      const props = attributesToProps(domNode.attribs)

      props.target = '_blank'
      props.rel = 'noopener noreferrer nofollow'

      if (Array.isArray(domNode.children) && typeof domNode.children[0] === 'object') {
        return <a {...props}>{(domNode.children[0] as any).data}</a>
      }
    }

    return domNode
  },
}
