import { Box } from '@mui/material'
import { Editor } from '@tiptap/react'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { useCallback, useMemo } from 'react'
import styles from './TextEditorMenu.module.css'

interface TextEditorMenuProps {
  editor: Editor | null
  disabled: boolean
}

export const TextEditorMenu = ({ editor, disabled }: TextEditorMenuProps) => {
  const setHyperlink = useCallback(() => {
    if (!editor) {
      return []
    }

    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  const controls = useMemo(() => {
    if (!editor) {
      return []
    }

    return [
      {
        icon: <FormatBoldIcon />,
        activeFn: () => editor.isActive('bold'),
        fn: () => editor.chain().focus().toggleBold().run(),
      },
      {
        icon: <FormatItalicIcon />,
        activeFn: () => editor.isActive('italic'),
        fn: () => editor.chain().focus().toggleItalic().run(),
      },
      {
        icon: <FormatUnderlinedIcon />,
        activeFn: () => editor.isActive('underline'),
        fn: () => editor.chain().focus().toggleUnderline().run(),
      },
      {
        icon: <StrikethroughSIcon />,
        activeFn: () => editor.isActive('strike'),
        fn: () => editor.chain().focus().toggleStrike().run(),
      },
      {
        icon: <LooksOneIcon />,
        activeFn: () => editor.isActive('heading', { level: 1 }),
        fn: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      },
      {
        icon: <LooksTwoIcon />,
        activeFn: () => editor.isActive('heading', { level: 2 }),
        fn: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      },
      {
        icon: <FormatListBulletedIcon />,
        activeFn: () => editor.isActive('bulletList'),
        fn: () => editor.chain().focus().toggleBulletList().run(),
      },
      {
        icon: <FormatListNumberedIcon />,
        activeFn: () => editor.isActive('orderedList'),
        fn: () => editor.chain().focus().toggleOrderedList().run(),
      },
      {
        icon: <FormatAlignLeftIcon />,
        activeFn: () => editor.isActive({ textAlign: 'left' }),
        fn: () => editor.chain().focus().setTextAlign('left').run(),
      },
      {
        icon: <FormatAlignCenterIcon />,
        activeFn: () => editor.isActive({ textAlign: 'center' }),
        fn: () => editor.chain().focus().setTextAlign('center').run(),
      },
      {
        icon: <FormatAlignRightIcon />,
        activeFn: () => editor.isActive({ textAlign: 'right' }),
        fn: () => editor.chain().focus().setTextAlign('right').run(),
      },
      {
        icon: <LinkIcon />,
        activeFn: () => editor.isActive('link'),
        fn: () => setHyperlink(),
      },
      {
        icon: <LinkOffIcon />,
        activeFn: () => editor.isActive('link'),
        fn: () => editor.chain().focus().unsetLink().run(),
      },
    ]
  }, [editor, setHyperlink])

  if (!editor) {
    return null
  }

  return (
    <Box className={styles.container}>
      {controls.map((control, index) => (
        <button
          key={index}
          type="button"
          disabled={disabled}
          className={control.activeFn() ? styles.active : undefined}
          onClick={control.fn}
        >
          {control.icon}
        </button>
      ))}
    </Box>
  )
}
