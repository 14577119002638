import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const MenuIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 24 24" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill={fill}
        d="M21 13H3c-.55 0-1-.45-1-1s.45-1 1-1h18c.55 0 1 .45 1 1s-.45 1-1 1zM21 7H3c-.55 0-1-.45-1-1s.45-1 1-1h18c.55 0 1 .45 1 1s-.45 1-1 1zM21 19H3c-.55 0-1-.45-1-1s.45-1 1-1h18c.55 0 1 .45 1 1s-.45 1-1 1z"
      />
    </SvgIcon>
  )
}
