import { SafeAreaWrap } from '../../components'
import { TocContent } from '../../components/Login'

export const Toc = () => {
  return (
    <SafeAreaWrap sx={{ p: 2 }}>
      <TocContent />
    </SafeAreaWrap>
  )
}
