import { AttachmentItem, CameraFile } from '../interfaces'

export const remapFileList = (fileList: (File | CameraFile | AttachmentItem)[]): AttachmentItem[] => {
  return fileList.map((file) => {
    if (file instanceof File) {
      return {
        src: URL.createObjectURL(file),
      }
    }

    if ('webPath' in file) {
      return {
        src: file.webPath,
      }
    }

    return file
  })
}
