import { Box, SelectChangeEvent, useMediaQuery } from '@mui/material'
import { DateRangeFilter } from '../DateRangeFilter'
import { FilterSelect } from '../FilterSelect'
import { RangeFilter } from '../../enums'
import { AggregatedUtilizationsItem, Municipality, FilterState } from '../../interfaces'
import { VIEWPORT_MD } from '../../constants'

interface FilterProps {
  filters: FilterState
  municipalities: Municipality[]
  aggregatedUtilization?: AggregatedUtilizationsItem[]
  loading: boolean
  onMunicipalityChange: (event: SelectChangeEvent<string>) => void
  onClearMunicipality: () => void
  onOfferChange?: (event: SelectChangeEvent<string>) => void
  onClearOffer?: () => void
  onRangeChange: (event: React.MouseEvent<HTMLElement>, range: RangeFilter) => void
  onDateChange: (dates: [Date | null, Date | null]) => void
}

export const UtilizationsFilters = ({
  filters,
  municipalities,
  aggregatedUtilization,
  loading,
  onMunicipalityChange,
  onClearMunicipality,
  onOfferChange,
  onClearOffer,
  onRangeChange,
  onDateChange,
}: FilterProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)
  const flexDirection = matches ? 'row' : 'column'

  return (
    <Box sx={{ display: 'flex', flexDirection, width: '100%', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FilterSelect
          name="municipality"
          value={filters.municipalityId}
          disabled={loading}
          onChange={onMunicipalityChange}
          options={municipalities}
          onClick={onClearMunicipality}
        />
        {aggregatedUtilization && onOfferChange && (
          <FilterSelect
            name="offer"
            value={filters.offerId}
            disabled={loading}
            onChange={onOfferChange}
            options={aggregatedUtilization}
            onClick={onClearOffer}
          />
        )}
      </Box>
      <DateRangeFilter
        disabled={loading}
        value={filters.selectedRange}
        onChange={onRangeChange}
        startDate={filters.startDate}
        endDate={filters.endDate}
        onDateChange={onDateChange}
      />
    </Box>
  )
}
