import { startOfWeek, endOfWeek, startOfDay, endOfDay } from 'date-fns'

export const today = new Date()
export const startOfToday = startOfDay(today)
export const endOfToday = endOfDay(today)

export const startOfThisWeek = startOfWeek(today)
export const endOfThisWeek = endOfWeek(today)

export const startOfThisMonth = startOfDay(new Date(today.getFullYear(), today.getMonth(), 1))
export const endOfThisMonth = endOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0))
