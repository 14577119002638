import { SvgIcon, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

interface IconProps {
  height: string
  width?: string
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  primaryColor: string
  secondaryColor: string
}

export const MobillyTxIcon = ({ height, width = 'auto', sx, primaryColor, secondaryColor }: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 184 78" sx={[{ width: width, height: height }, sx || {}]}>
      <path
        d="M12.5748 0C-8.71447 0 -26 17.2855 -26 38.5748C-26 59.8641 -8.71447 77.1497 12.5748 77.1497C33.8641 77.1497 51.1497 59.8641 51.1497 38.5748C51.1497 17.2855 33.9026 0 12.5748 0ZM32.5552 52.4725C32.5552 54.3974 31.0153 55.9373 29.0904 55.9373C27.1655 55.9373 25.5871 54.3974 25.5871 52.4725V31.5297L16.8481 53.127C16.0781 54.8979 14.4227 55.9758 12.5748 55.9758C10.7269 55.9758 9.07152 54.8979 8.30157 53.1655L-0.43744 31.5297V52.511C-0.43744 54.4359 -2.01585 55.9758 -3.94074 55.9758C-5.86563 55.9758 -7.40555 54.4359 -7.40555 52.511V23.5992C-7.40555 21.1353 -5.44216 19.1334 -3.0168 19.1334V18.4405L-2.74731 19.1334H-1.39989C0.371014 19.1334 2.02642 20.2499 2.71938 21.9438L12.5363 46.4284L22.3533 21.9823C23.0462 20.2884 24.7016 19.1719 26.4725 19.1719H27.82L28.0895 18.479V19.1719C30.5148 19.1719 32.4782 21.1738 32.4782 23.6377V52.511L32.5552 52.4725Z"
        fill={primaryColor}
      />
      <path
        d="M70.0314 38.5747C70.0314 40.8846 71.9181 42.7709 74.2284 42.7709C76.5387 42.7709 78.4255 40.8846 78.4255 38.5747C78.4255 36.2648 76.5387 34.3784 74.2284 34.3784C71.9181 34.3784 70.0314 36.2648 70.0314 38.5747Z"
        fill={secondaryColor}
      />
      <path
        d="M90.1694 38.5747C90.1694 41.4236 92.4797 43.7334 95.3291 43.7334C98.1785 43.7334 100.527 41.4236 100.527 38.5747C100.527 35.7259 98.1785 33.416 95.3291 33.416C92.4797 33.416 90.1694 35.7259 90.1694 38.5747"
        fill={secondaryColor}
      />
      <path
        d="M112.311 38.5749C112.311 42.0782 115.121 44.8885 118.625 44.8885C122.129 44.8885 124.94 42.0782 124.94 38.5749C124.94 35.0716 122.129 32.2612 118.625 32.2612C115.121 32.2612 112.311 35.0716 112.311 38.5749Z"
        fill={secondaryColor}
      />
      <path
        d="M152.554 45.663C152.091 45.663 151.668 45.7784 151.321 45.8554C150.898 45.9709 150.513 46.0479 149.858 46.0479C148.588 46.0479 148.202 45.9324 148.202 44.585V32.1887H152.053C153.747 32.1887 155.095 30.8413 155.095 29.1474C155.095 27.4535 153.747 26.1061 152.053 26.1061H148.202V23.7577C148.202 22.5258 147.278 21.6018 146.085 21.6018C145.199 21.6018 144.621 22.1793 144.429 22.3718L138.345 28.3389C137.806 28.8009 137.498 29.4169 137.498 30.0713C137.498 31.2648 138.422 32.1887 139.616 32.1887H141.58V44.9315C141.58 47.6263 142.581 52.1691 149.473 52.1691C150.975 52.1691 152.4 51.8611 153.786 51.2451C154.902 50.6677 155.518 49.7052 155.518 48.5118C155.518 46.8949 154.209 45.6245 152.631 45.6245"
        fill={secondaryColor}
      />
      <path
        d="M176.799 38.8488L183.307 31.1877C183.769 30.5717 184 29.8403 184 29.1473C184 27.3379 182.537 25.9135 180.65 25.9135C179.61 25.9135 178.648 26.3755 178.07 27.1454L172.756 33.7286L167.443 27.1839C166.865 26.3755 165.864 25.875 164.786 25.875C162.86 25.875 161.474 27.2609 161.474 29.1858C161.474 29.9173 161.744 30.6102 162.244 31.3032L168.598 39.0413L162.244 46.5483C161.782 47.1643 161.551 47.8573 161.551 48.5887C161.551 50.5521 162.86 51.938 164.747 51.938C165.825 51.938 166.826 51.4376 167.52 50.5521L172.602 44.1615L177.954 50.5906C178.571 51.4376 179.533 51.938 180.573 51.938C182.768 51.938 183.923 50.2441 183.923 48.5887C183.923 47.8188 183.615 47.0103 183.153 46.4713L176.799 38.7718V38.8488Z"
        fill={secondaryColor}
      />
    </SvgIcon>
  )
}
