import { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { useDetectKeyboard } from '../../hooks'
import { Capacitor } from '@capacitor/core'

export const SafeAreaTopOverlay = () => {
  const ref = useRef<HTMLDivElement>(null)
  const scrollListenerRef = useRef(() => {
    if (ref.current) {
      ref.current.style.top = (document.documentElement.scrollTop || document.body.scrollTop) + 'px'
    }
  })

  const isKeyboardOpen = useDetectKeyboard()

  useEffect(() => {
    /*
     * in order to somewhat imitate native app,
     * top notch (safe area) gets covered with transparent block (with fixed position)
     *
     * issue is that mobile Safari does not support fixed position when input is focused and virtual keyboard is displayed
     * in order to work around, we listen for keyboard opening/closing
     * and toggle between fixed and absolute (with specific top offset) positions
     *
     * alternatively, we could have used @capacitor/keyboard plugin
     * with resize configured as native (similarly how it works on Capacitor v2)
     * but keyboard opening is not as smooth and because of that, former option was chosen
     */

    const scrollListener = scrollListenerRef.current

    if (ref.current && Capacitor.getPlatform() === 'ios') {
      const safeAreaTopHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--sat'))

      if (safeAreaTopHeight > 0) {
        if (isKeyboardOpen) {
          ref.current.style.position = 'absolute'
          ref.current.style.top = window.scrollY + 'px'

          document.addEventListener('scroll', scrollListener)
        } else {
          ref.current.style.position = 'fixed'
          ref.current.style.top = '0px'

          document.removeEventListener('scroll', scrollListener)
        }
      }
    }

    return () => {
      document.removeEventListener('scroll', scrollListener)
    }
  }, [isKeyboardOpen])

  return (
    <Box
      ref={ref}
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 3000,
        width: '100%',
        paddingTop: '4rem',
        transform: 'translateY(-4rem)',
        height: 'env(safe-area-inset-top, 0)',
        background: '#FFF',
      }}
    />
  )
}
