import { ApolloProvider } from './providers'
import { createTheme, ThemeProvider } from '@mui/material'
import { Core, ErrorBoundary, Init } from './containers'
import { BrowserRouter } from 'react-router-dom'
import { routesConfig } from './routesConfig'
import { theme } from './constants'
import { ConfirmDialog, UpdateCheck } from './components'
import { useRef } from 'react'
import { Capacitor } from '@capacitor/core'

function App() {
  const platform = useRef(Capacitor.getPlatform())

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <BrowserRouter>
        <ErrorBoundary>
          <Init>
            <ApolloProvider>
              <Core routesConfig={routesConfig} />
            </ApolloProvider>
          </Init>
          <ConfirmDialog />
          {platform.current !== 'web' ? <UpdateCheck /> : <></>}
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
