import { Typography } from '@mui/material'

interface SectionTitleProps {
  label: string
}

export const SectionTitle = ({ label }: SectionTitleProps) => {
  return (
    <Typography variant="h2" sx={{ color: '#606060' }}>
      {label}
    </Typography>
  )
}
