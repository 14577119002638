import { useState, useMemo } from 'react'
import { IconButton, ImageList, ImageListItem, Theme, SxProps, ImageListItemBar } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { FullScreenImagePreview } from '../FullScreenImagePreview'
import { AttachmentItem } from '../../interfaces'
import useMediaQuery from '@mui/material/useMediaQuery'
import { VIEWPORT_MD } from '../../constants'

interface StaticGalleryProps {
  images: AttachmentItem[]
  canDelete?: boolean
  onDelete?: (index: number) => void
  sx?: SxProps<Theme>
  cols?: number
}

export const StaticGallery = ({ images, canDelete, onDelete, sx, cols = 4 }: StaticGalleryProps) => {
  const [previewSrc, setPreviewSrc] = useState<AttachmentItem>()
  const imageListStyle = useMemo(() => ({ m: 0, ...sx }), [sx])
  const matches = useMediaQuery(VIEWPORT_MD)

  const onClose = () => {
    setPreviewSrc(undefined)
  }

  const openFullScreenPreview = (index: number, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.preventDefault()

    const img = images[index]

    if (!img) {
      return
    }

    setPreviewSrc({
      src: img.src,
      name: img.name,
    })
  }

  const deleteImage = (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()

    onDelete && onDelete(index)
  }

  return (
    <>
      <ImageList onClick={(event) => event.preventDefault()} cols={matches ? 6 : 3} gap={16} sx={imageListStyle}>
        {images.map((image, index) => (
          <ImageListItem
            key={index}
            sx={{
              p: 0,
              border: '1px solid #eee',
              borderRadius: 2,
              cursor: 'pointer',
              color: '#fff',
              '.MuiImageListItemBar-titleWrap': {
                display: 'none',
              },
              '.MuiImageListItemBar-actionIcon': {
                mt: 1,
                width: '100%',
                textAlign: 'center',
              },
            }}
            onClick={(event) => openFullScreenPreview(index, event)}
          >
            <img src={image.src} alt={image.name} />
            {canDelete && (
              <ImageListItemBar
                position="below"
                actionIcon={
                  <IconButton
                    onClick={(event) => deleteImage(index, event)}
                    aria-label={`Dzēst ${index + 1} attēlu, ${image.name}`}
                  >
                    <DeleteForeverIcon fontSize="large" />
                  </IconButton>
                }
              ></ImageListItemBar>
            )}
          </ImageListItem>
        ))}
      </ImageList>
      {previewSrc && <FullScreenImagePreview open={!!previewSrc} previewSrc={previewSrc} onClose={onClose} />}
    </>
  )
}
