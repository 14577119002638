import { useSvgParams } from '../../hooks'
import { SvgIcon } from '@mui/material'
import { SvgIconsProps } from '../../interfaces'

export const QrIcon = ({ color, size, sx }: SvgIconsProps) => {
  const [dimension, fill] = useSvgParams({ size, color })

  return (
    <SvgIcon viewBox="0 0 66 66" sx={[{ width: dimension, height: dimension }, sx || {}]}>
      <path
        fill={fill}
        d="M37.46 30.8h13.43c1.24 0 2.25-1.01 2.25-2.25V15.12c0-1.24-1.01-2.25-2.25-2.25H37.46c-1.24 0-2.25 1.01-2.25 2.25v13.43c0 1.24 1.01 2.25 2.25 2.25zm2.25-13.43h8.93v8.93h-8.93v-8.93zM28.5 12.87H15.07c-1.24 0-2.25 1.01-2.25 2.25v13.43c0 1.24 1.01 2.25 2.25 2.25H28.5c1.24 0 2.25-1.01 2.25-2.25V15.12c0-1.24-1.01-2.25-2.25-2.25zM26.25 26.3h-8.93v-8.93h8.93v8.93zM28.5 35.2H15.07c-1.24 0-2.25 1.01-2.25 2.25v13.43c0 1.24 1.01 2.25 2.25 2.25H28.5c1.24 0 2.25-1.01 2.25-2.25V37.45c0-1.24-1.01-2.25-2.25-2.25zm-2.25 13.43h-8.93V39.7h8.93v8.93z"
      />
      <rect fill={fill} x="35.22" y="35.16" width="5.99" height="5.99" rx="1.2" ry="1.2" />
      <rect fill={fill} x="47.19" y="35.16" width="5.99" height="5.99" rx="1.2" ry="1.2" />
      <rect fill={fill} x="41.21" y="41.15" width="5.99" height="5.99" rx="1.2" ry="1.2" />
      <rect fill={fill} x="35.22" y="47.13" width="5.99" height="5.99" rx="1.19" ry="1.19" />
      <rect fill={fill} x="47.19" y="47.13" width="5.99" height="5.99" rx="1.19" ry="1.19" />
    </SvgIcon>
  )
}
