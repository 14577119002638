import {
  format,
  formatISODuration,
  intervalToDuration,
  addYears,
  parseISO,
  addMonths,
  addDays,
  addHours,
  addMinutes,
  addSeconds,
} from 'date-fns'
import { DEFAULT_DATE_TIME_FORMAT } from '../constants'
import { parse as parseDuration } from 'tinyduration'

export const parseIsoString = (dateString: string | undefined) => {
  if (dateString) {
    try {
      return new Date(dateString)
    } catch {
      return null
    }
  }

  return null
}

export const parseDateTime = (dateString: string | undefined) => {
  if (dateString) {
    try {
      return format(new Date(dateString), DEFAULT_DATE_TIME_FORMAT)
    } catch {
      return ''
    }
  }

  return ''
}

export const parseDateObj = (date: Date | null) => {
  if (date) {
    try {
      return format(date, DEFAULT_DATE_TIME_FORMAT)
    } catch {
      return ''
    }
  }

  return ''
}

export const getISOString = (date: Date | null) => {
  if (!date) {
    return ''
  }

  try {
    return date.toISOString()
  } catch {
    return null
  }
}

export const getDuration = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) {
    return ''
  }

  try {
    const duration = intervalToDuration({
      start: startDate,
      end: endDate,
    })

    return formatISODuration(duration)
  } catch {
    return null
  }
}

export const getEndsAt = (startsAt: string | undefined, duration: string | undefined) => {
  if (startsAt && duration) {
    try {
      const durationObj = parseDuration(duration)

      let endsAt = parseISO(startsAt)

      if (durationObj.years) {
        endsAt = addYears(endsAt, durationObj.years)
      }

      if (durationObj.months) {
        endsAt = addMonths(endsAt, durationObj.months)
      }

      if (durationObj.days) {
        endsAt = addDays(endsAt, durationObj.days)
      }

      if (durationObj.hours) {
        endsAt = addHours(endsAt, durationObj.hours)
      }

      if (durationObj.minutes) {
        endsAt = addMinutes(endsAt, durationObj.minutes)
      }

      if (durationObj.seconds) {
        endsAt = addSeconds(endsAt, durationObj.seconds)
      }

      return endsAt
    } catch {
      return null
    }
  }

  return null
}

export const getTimeInterval = (startsAt: Date | null, endsAt: Date | null) => {
  let result = ''

  if (startsAt) {
    try {
      if (!(startsAt instanceof Date)) {
        startsAt = new Date(startsAt)
      }

      result += format(startsAt, DEFAULT_DATE_TIME_FORMAT)
    } catch {}
  }

  if (endsAt) {
    try {
      if (startsAt) {
        if (result.length > 0) {
          result += ' - '
        }

        if (!(endsAt instanceof Date)) {
          endsAt = new Date(endsAt)
        }

        result += format(endsAt, DEFAULT_DATE_TIME_FORMAT)
      }
    } catch {}
  }

  return result
}
