import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { VIEWPORT_MD } from '../../constants'

interface FormRowProps {
  label: string
  children: JSX.Element | boolean | (JSX.Element | boolean)[]
}

export const FormRow = ({ label, children }: FormRowProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)

  return (
    <>
      {matches ? (
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={4}>
            <Typography component="span">{label}</Typography>
          </Grid>
          <Grid item xs={8}>
            {children}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="span">{label}</Typography>
          {children}
        </Box>
      )}
    </>
  )
}
