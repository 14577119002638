import { SelectChangeEvent, Box, Select, MenuItem, IconButton, useMediaQuery } from '@mui/material'
import { Municipality, AggregatedUtilizationsItem } from '../../interfaces'
import { VIEWPORT_MD } from '../../constants'
import ClearIcon from '@mui/icons-material/Clear'

interface FilterSelectProps {
  value?: string
  name: string
  defaultValue?: string
  options: Municipality[] | AggregatedUtilizationsItem[]
  disabled?: boolean
  onChange: (event: SelectChangeEvent) => void
  onClick?: () => void
}

export const FilterSelect = ({ options, defaultValue, name, onChange, disabled, value = '', onClick }: FilterSelectProps) => {
  const matches = useMediaQuery(VIEWPORT_MD)

  const width = matches ? '285px' : '100%'
  const mr = matches ? 1 : 0

  return (
    <Box sx={{ mb: 2, mr }}>
      <Select
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        sx={{ width, '.MuiSelect-iconOutlined': { display: value ? 'none' : '' } }}
        endAdornment={
          value && (
            <IconButton onClick={onClick}>
              <ClearIcon />
            </IconButton>
          )
        }
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.id} sx={{ whiteSpace: 'normal' }}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
