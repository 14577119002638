import {
  REQUIRED_CAPITAL_LETTER,
  REQUIRED_ERROR_MESSAGE,
  REQUIRED_NUMBER,
  REQUIRED_PASSWORD_LENGTH,
  REQUIRED_SPECIAL_CHARACTER,
  REQUIRED_LOWER_CASE_LETTER,
  PASSWORDS_DO_NOT_MATCH,
  PASSWORD_TOO_LONG,
  PASSWORD_CONTAINS_USERNAME,
  OLD_AND_NEW_PASSWORDS_MATCH,
  HAS_NON_LATIN_CHARACTERS,
} from '../constants'
import { StringObject, PasswordFormData } from '../interfaces'

export const validatePassword = (formData: PasswordFormData, username: string): [number, StringObject] => {
  const errors: StringObject = {}

  if (!formData.oldPassword) {
    errors['oldPassword'] = REQUIRED_ERROR_MESSAGE
  }
  if (formData.newPassword.toLowerCase().includes(username.toLowerCase())) {
    errors['newPassword'] = PASSWORD_CONTAINS_USERNAME
  }
  if (formData.newPassword.toLowerCase().includes(username.toLowerCase().split('').reverse().join(''))) {
    errors['newPassword'] = PASSWORD_CONTAINS_USERNAME
  }
  if (!/[A-Z]/.test(formData.newPassword)) {
    errors['newPassword'] = REQUIRED_CAPITAL_LETTER
  }
  if (!/[a-z]/.test(formData.newPassword)) {
    errors['newPassword'] = REQUIRED_LOWER_CASE_LETTER
  }
  if (!/\d/.test(formData.newPassword)) {
    errors['newPassword'] = REQUIRED_NUMBER
  }
  // eslint-disable-next-line
  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(formData.newPassword)) {
    errors['newPassword'] = REQUIRED_SPECIAL_CHARACTER
  }
  if (formData.newPassword.length < 10) {
    errors['newPassword'] = REQUIRED_PASSWORD_LENGTH
  }
  if (formData.newPassword.length > 64) {
    errors['newPassword'] = PASSWORD_TOO_LONG
  }
  // eslint-disable-next-line
  if (/[^a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(formData.newPassword)) {
    errors['newPassword'] = HAS_NON_LATIN_CHARACTERS
  }
  if (!formData.newPasswordConfirm) {
    errors['newPasswordConfirm'] = REQUIRED_ERROR_MESSAGE
  }
  if (formData.newPassword !== formData.newPasswordConfirm) {
    errors['newPasswordConfirm'] = PASSWORDS_DO_NOT_MATCH
  }

  if (formData.newPassword === formData.oldPassword) {
    errors['newPassword'] = OLD_AND_NEW_PASSWORDS_MATCH
  }
  return [Object.keys(errors).length, errors]
}
