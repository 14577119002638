import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import { BackButton, FooterButton, ResultWrap } from '../../components'
import { useVerifyStore } from '../../store'
import { QrIcon } from '../../icons'

export const ResultOffers = () => {
  const navigate = useNavigate()

  const { offers, qrCodeOwner, setOffer } = useVerifyStore()

  if (offers == null) {
    return <Navigate to="/" />
  }

  const onOffer = (index: number) => {
    if (offers) {
      setOffer(offers[index])
      navigate('/result/success/offer')
    }
  }

  const onScanner = () => {
    navigate('/scanner')
  }

  return (
    <ResultWrap>
      <Typography
        variant="h1"
        sx={{
          mt: '150px',
          textTransform: 'uppercase',
          lineHeight: 1.5,
        }}
      >
        Kods atpazīts
      </Typography>
      <Box>
        <Typography sx={{ fontSize: '1.8rem' }}>{`${qrCodeOwner?.firstName} ${qrCodeOwner?.lastName}`}</Typography>
        <Typography sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{qrCodeOwner?.userId}</Typography>
      </Box>
      <Typography sx={{ mt: 4 }}>Pieejamās lojalitātes atlaides:</Typography>
      <List disablePadding>
        {offers &&
          offers.map((offer, index) => (
            <ListItem disablePadding key={index} sx={{ mt: 2 }}>
              <Button onClick={() => onOffer(index)} fullWidth variant="contained">
                {offer.offer.message.title?.all.lv}
              </Button>
            </ListItem>
          ))}
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 'auto' }}>
        <FooterButton buttonLabel="Skenēt QR kodu" onClick={onScanner}>
          <QrIcon color="accent" size="small" />
        </FooterButton>
        <BackButton />
      </Box>
    </ResultWrap>
  )
}
