import { OfferUtilizationsListItem } from '../../interfaces'
import { format } from 'date-fns'
import { GetOfferUtilizationsQuery } from '../../graphql'
import { DEFAULT_DATE_TIME_FORMAT } from '../../constants'

export const mapOfferUtilizations = (queryResult: GetOfferUtilizationsQuery): OfferUtilizationsListItem[] => {
  try {
    const offerUtilizationsList = queryResult.loyalty.getOfferUtilizations.map((item) => {
      return {
        occurredAt: format(new Date(item.occurredAt), DEFAULT_DATE_TIME_FORMAT),
      }
    })
    return offerUtilizationsList
  } catch {
    return []
  }
}
