import { useState, useEffect, useRef } from 'react'
import { InfiniteList, StickyFooter, FooterButton, UtilizationsFilters, BackButton } from '../../../components'
import { Box, Typography, Divider, SelectChangeEvent, ListItemButton, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetAggregatedOffersQuery, useGetMunicipalitiesQuery, useGetOfferUtilizationsLazyQuery } from '../../../graphql'
import {
  mapOfferUtilizations,
  mapAggregatedUtilizations,
  mapMunicipalities,
  getDateRange,
  xlsxExporter,
  xlsxMobileExporter,
  updateSearchParams,
  getSelectedRange,
} from '../../../utils'
import { AggregatedUtilizationsItem, Municipality, OfferUtilizationsListItem, FilterState } from '../../../interfaces'
import { DEFAULT_ENTRIES_PER_PAGE, LOCALE_STRING_FORMAT, VIEWPORT_MD } from '../../../constants'
import { RangeFilter } from '../../../enums'
import { useSnackbarStore } from '../../../store'
import { Capacitor } from '@capacitor/core'

export const OfferUtilizationsList = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const matches = useMediaQuery(VIEWPORT_MD)
  const theme = useTheme()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const mobile = Capacitor.getPlatform() !== 'web'
  const backgroundColor = mobile ? theme.palette.secondary.main : '#414042'
  const [filters, setFilters] = useState<FilterState>({
    startDate: null,
    endDate: null,
    selectedRange: RangeFilter.NONE,
  })

  const [aggregatedUtilization, setAggregatedUtilization] = useState<AggregatedUtilizationsItem[]>([])
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])
  const [selectedAggregatedUtilization, setSelectedAggregatedUtilization] = useState<AggregatedUtilizationsItem | null>(null)

  const [searchParams] = useSearchParams()
  const [downloading, setDownloading] = useState(false)

  const { data: municipalitiesData, loading: loadingMunicipalities } = useGetMunicipalitiesQuery()
  const { data: aggregatedOffersData, loading: offersLoading } = useGetAggregatedOffersQuery({
    variables: { filter: { municipalityId: filters.municipalityId as string } },
  })
  const [getUtilizations, { data, loading }] = useGetOfferUtilizationsLazyQuery()
  const [currentPage, setCurrentPage] = useState(1)
  const utilizationsList = useRef<OfferUtilizationsListItem[]>([])
  const [pagedUtilizationsList, setPagedUtilizationsList] = useState<OfferUtilizationsListItem[]>([])

  const width = matches ? '576px' : '100%'

  useEffect(() => {
    const startParam = searchParams.get('start')
    const endParam = searchParams.get('end')
    const municipalityIdParam = searchParams.get('munId')

    setFilters({
      ...filters,
      startDate: startParam ? new Date(startParam) : null,
      endDate: endParam ? new Date(endParam) : null,
      offerId: id,
      municipalityId: municipalityIdParam ? municipalityIdParam : undefined,
      selectedRange: startParam && endParam ? getSelectedRange(startParam, endParam) : RangeFilter.NONE,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const initialIndex = aggregatedUtilization.findIndex((item) => item.id === filters.offerId)
    setSelectedAggregatedUtilization(aggregatedUtilization[initialIndex])
  }, [aggregatedUtilization, filters.offerId])

  useEffect(() => {
    if (municipalitiesData) {
      setMunicipalities(mapMunicipalities(municipalitiesData))
    }
  }, [municipalitiesData])

  useEffect(() => {
    if (aggregatedOffersData) {
      setAggregatedUtilization(mapAggregatedUtilizations(aggregatedOffersData))
    }
  }, [aggregatedOffersData])

  useEffect(() => {
    updateSearchParams(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (filters.offerId === undefined) {
      utilizationsList.current = []
      setPagedUtilizationsList([])
    } else {
      getUtilizations({
        variables: {
          filter: {
            temporal: { from: filters.startDate?.toISOString(), to: filters.endDate?.toISOString() },
            offerId: filters.offerId as string,
            municipalityId: filters.municipalityId as string,
          },
        },
      })
      setCurrentPage(1)
      if (data) {
        const list = mapOfferUtilizations(data)
        utilizationsList.current = list
        setPagedUtilizationsList(list.slice(0, DEFAULT_ENTRIES_PER_PAGE))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, data])

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setFilters({
      ...filters,
      startDate: start,
      endDate: end,
      selectedRange: RangeFilter.NONE,
    })
  }

  const onRangeChange = (event: React.MouseEvent<HTMLElement>, range: RangeFilter) => {
    const { start, end } = getDateRange(range)
    setFilters({
      ...filters,
      selectedRange: range,
      startDate: start,
      endDate: end,
    })
  }

  const onOfferChange = (event: SelectChangeEvent<string>) => {
    const newOfferId = event.target.value
    setFilters({
      ...filters,
      offerId: newOfferId,
    })
    if (!mobile) {
      const searchParams = new URLSearchParams(location.search)
      navigate({
        pathname: `/offers/utilizations/${newOfferId}`,
        search: searchParams.toString(),
      })
    }
  }

  const onMunicipalityChange = (event: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      municipalityId: event.target.value,
    })
  }

  const onClearMunicipality = () => {
    setFilters({
      ...filters,
      municipalityId: undefined,
      offerId: undefined,
    })
  }

  const onClearOffer = () => {
    setFilters({
      ...filters,
      offerId: undefined,
    })
  }

  const onLoadMore = () => {
    const nextPage = currentPage + 1
    const nextEntriesCount = nextPage * DEFAULT_ENTRIES_PER_PAGE

    setCurrentPage(nextPage)

    if (nextEntriesCount >= utilizationsList.current.length) {
      setPagedUtilizationsList(utilizationsList.current)
      return
    }

    setPagedUtilizationsList(utilizationsList.current.slice(0, nextEntriesCount))
  }

  const onXlsxExport = () => {
    // Column titles and data for xlsx file to export, first column width and file name
    const columnTitles = selectedAggregatedUtilization ? [`${selectedAggregatedUtilization.title}`] : []
    const exportData = pagedUtilizationsList.map(({ occurredAt }) => [occurredAt])
    const maxWidth = pagedUtilizationsList.reduce((w, r) => Math.max(w, r.occurredAt.length), 10)
    const fileName = `${
      selectedAggregatedUtilization
        ? `${selectedAggregatedUtilization.title} ${
            filters.startDate ? filters.startDate.toLocaleDateString('lv-LV', LOCALE_STRING_FORMAT) + ' -' : 'atskaite par visu'
          } ${filters.endDate ? filters.endDate.toLocaleDateString('lv-LV', LOCALE_STRING_FORMAT) : 'laika periodu'}`
        : 'Piedāvājuma utilizāciju atskaite'
    }`
    if (mobile) {
      xlsxMobileExporter(exportData, fileName, maxWidth, columnTitles, setDownloading, showSnackbar)
    } else {
      xlsxExporter(exportData, fileName, maxWidth, columnTitles, setDownloading, showSnackbar)
    }
  }

  const renderListItem = (utilizedItem: OfferUtilizationsListItem) => {
    return (
      <ListItemButton divider sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
            {utilizedItem.occurredAt}
          </Typography>
        </Box>
      </ListItemButton>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <UtilizationsFilters
        filters={filters}
        municipalities={municipalities}
        aggregatedUtilization={aggregatedUtilization}
        loading={loading || loadingMunicipalities || offersLoading}
        onMunicipalityChange={onMunicipalityChange}
        onClearMunicipality={onClearMunicipality}
        onOfferChange={onOfferChange}
        onClearOffer={onClearOffer}
        onRangeChange={onRangeChange}
        onDateChange={onDateChange}
      />
      <Divider sx={{ width: '100%' }} />
      <InfiniteList
        loading={loading || loadingMunicipalities || offersLoading}
        renderFn={renderListItem}
        onLoadMore={onLoadMore}
        list={pagedUtilizationsList}
        width={width}
      />
      <StickyFooter>
        <FooterButton
          disabled={loading || downloading || loadingMunicipalities}
          onClick={onXlsxExport}
          buttonLabel="Saglabāt atskaiti"
          sx={{ '&:hover': { backgroundColor } }}
        ></FooterButton>
        {!matches ? <BackButton /> : <></>}
      </StickyFooter>
    </Box>
  )
}
