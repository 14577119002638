import { MessageOutputLyl, MessageOutputPev } from '../graphql'
import { parseIsoString } from './timeUtils'
import { sortByCreatedAt } from './miscellaneousUtils'
import { CommentItem } from '../interfaces'

export const mapComments = (comments: (MessageOutputLyl | MessageOutputPev)[]): CommentItem[] => {
  try {
    const commentsList = comments.map((comment) => {
      const replies = comment.replies
        ? comment.replies.map((item) => ({
            id: item.id,
            createdAt: parseIsoString(item.createdAt),
            content: item.content.all.lv,
          }))
        : []

      return {
        id: comment.id,
        createdAt: parseIsoString(comment.createdAt),
        content: comment.content.all.lv,
        comments: sortByCreatedAt(replies, 'asc'),
      }
    })

    return sortByCreatedAt(commentsList, 'asc')
  } catch {
    return []
  }
}

export const pushReply = (comments: CommentItem[], comment: CommentItem, id: string): CommentItem[] => {
  const copy = comments.slice()

  for (const item of copy) {
    if (item.id === id) {
      if (item.comments) {
        item.comments.push(comment)
      } else {
        item.comments = [comment]
      }

      break
    }
  }

  return copy
}
