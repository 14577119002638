import { useEffect, useState } from 'react'
import { ForcedUpdate } from '@city/capacitor-forced-update-plugin'
import { UpdateDialog } from '../UpdateDialog'

export const UpdateCheck = () => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

  useEffect(() => {
    ForcedUpdate.isUpdateAvailable().then((result) => {
      setIsUpdateAvailable(result.value)
    })
  }, [])

  return <>{isUpdateAvailable && <UpdateDialog />}</>
}
