import { Box } from '@mui/material'
import parse from 'html-react-parser'
import { htmlParseOptions } from '../../utils'

interface ContentWrapProps {
  content: string
}

export const ContentWrap = ({ content }: ContentWrapProps) => {
  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid #eee',
        width: '95%',
        borderRadius: 2,
      }}
    >
      {parse(content, htmlParseOptions)}
    </Box>
  )
}
