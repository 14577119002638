import { Box, Button, Typography, useTheme } from '@mui/material'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { BackButton, ResultWrap } from '../../components'
import { DEFAULT_DATE_TIME_FORMAT } from '../../constants'
import { useUtilizeOfferMutation } from '../../graphql'
import { useSnackbarStore, useVerifyStore } from '../../store'

export const ResultOffer = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const [utilizeMutation, { error, loading }] = useUtilizeOfferMutation()

  const { offer, parsedQrCode, qrCodeOwner } = useVerifyStore()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  useEffect(() => {
    if (error) {
      showSnackbar('Kaut kas nogāja greizi!', { severity: 'error' })
    }
  }, [error, showSnackbar])

  if (offer == null) {
    return <Navigate to="/" />
  }

  const onUtilize = () => {
    if (offer && parsedQrCode) {
      utilizeMutation({ variables: { id: offer.offer.id, input: parsedQrCode } }).then(() => {
        navigate('/result/success/utilized', { replace: true })
      })
    }
  }

  return (
    <ResultWrap>
      <Typography
        variant="h1"
        sx={{
          mt: '150px',
          textTransform: 'uppercase',
          lineHeight: 1.5,
        }}
      >
        Kods atpazīts
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ fontSize: '1.8rem' }}>{`${qrCodeOwner?.firstName} ${qrCodeOwner?.lastName}`}</Typography>
        <Typography sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{qrCodeOwner?.userId}</Typography>
        <Typography>Pēdējo reizi validēts:</Typography>
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '2rem' }}>
          {offer.lastUtilizedAt ? format(new Date(offer.lastUtilizedAt), DEFAULT_DATE_TIME_FORMAT) : '--.--.----. --:--'}
        </Typography>
      </Box>
      <Typography sx={{ mt: 4, fontSize: '2rem', fontWeight: 700 }}>{offer.offer.message.title?.all.lv}</Typography>
      <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={onUtilize} disabled={loading} sx={{ width: '85%' }} variant="contained" size="large" color="secondary">
          Piemērot
        </Button>
        <BackButton />
      </Box>
    </ResultWrap>
  )
}
