import create from 'zustand'
import { QrDataInputLyl, UtilizedOfferOutputLyl } from '../graphql'
import { QrCodeOwner } from '../interfaces'

interface VerifyStore {
  rawQrCode: string | undefined
  parsedQrCode: QrDataInputLyl | undefined
  error: string | undefined
  offer: UtilizedOfferOutputLyl | undefined
  offers: UtilizedOfferOutputLyl[] | undefined
  qrCodeOwner: QrCodeOwner | undefined
  setRawQrCode: (nextRawQrCode: string | undefined) => void
  setParsedQrCode: (nextParsedQrCode: QrDataInputLyl | undefined) => void
  setError: (nextError: string | undefined) => void
  setOffer: (nextOffer: UtilizedOfferOutputLyl | undefined) => void
  setOffers: (nextOffers: UtilizedOfferOutputLyl[] | undefined) => void
  setQrCodeOwner: (nextOwner: QrCodeOwner | undefined) => void
  reset: () => void
}

export const useVerifyStore = create<VerifyStore>((set) => ({
  rawQrCode: undefined,
  parsedQrCode: undefined,
  error: undefined,
  offer: undefined,
  offers: undefined,
  qrCodeOwner: undefined,
  setRawQrCode: (nextRawQrCode: string | undefined) => {
    set(() => ({ rawQrCode: nextRawQrCode }))
  },
  setParsedQrCode: (nextParsedQrCode: QrDataInputLyl | undefined) => {
    set(() => ({ parsedQrCode: nextParsedQrCode }))
  },
  setError: (nextError: string | undefined) => {
    set(() => ({ error: nextError }))
  },
  setOffer: (nextOffer: UtilizedOfferOutputLyl | undefined) => {
    set(() => ({ offer: nextOffer }))
  },
  setOffers: (nextOffers: UtilizedOfferOutputLyl[] | undefined) => {
    set(() => ({ offers: nextOffers }))
  },
  setQrCodeOwner: (nextOwner: QrCodeOwner | undefined) => {
    set(() => ({ qrCodeOwner: nextOwner }))
  },
  reset: () => {
    set(() => ({
      rawQrCode: undefined,
      parsedQrCode: undefined,
      error: undefined,
      offer: undefined,
      offers: undefined,
      qrCodeOwner: undefined,
    }))
  },
}))
