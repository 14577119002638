import { Box, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { FormEvent, forwardRef, useImperativeHandle, useRef } from 'react'
import { AddressAutocomplete, CameraButton, FileUploadButton, FormActions, FormRow, StaticGallery, TextEditor } from '..'
import {
  ACCEPTED_FILE_TYPES,
  ACCEPTED_UPLOAD_FILE_TYPES,
  DEFAULT_DATE_TIME_FORMAT,
  MAX_RICH_TEXT_LENGTH,
} from '../../constants'
import { AttachmentItem, CameraFile, EventEntry, Municipality, StringObject, Tag, TextEditorRef } from '../../interfaces'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Capacitor } from '@capacitor/core'
import { TagSelect } from '../TagSelect'
import lvLocale from 'date-fns/locale/lv'

interface EventsFormProps {
  eventItem: EventEntry
  disabled: boolean
  formErrors: StringObject
  images: AttachmentItem[]
  municipalities?: Municipality[]
  loadingTags?: boolean
  locationTags: Tag[]
  categoryTags: Tag[]
  onChange: (event: EventEntry, errors: StringObject) => void
  onSubmit: (event: FormEvent) => void
  onDeleteImage: (index: number) => void
  onWebImage: (files: File[]) => void
  onAppImage: (file: CameraFile) => void
}

export const EventForm = forwardRef(
  (
    {
      eventItem,
      disabled,
      formErrors,
      images,
      municipalities,
      loadingTags = false,
      locationTags,
      categoryTags,
      onChange,
      onSubmit,
      onDeleteImage,
      onWebImage,
      onAppImage,
    }: EventsFormProps,
    ref
  ) => {
    const editorRef = useRef<TextEditorRef>(null)

    useImperativeHandle(ref, () => ({
      getText() {
        if (editorRef.current) {
          return editorRef.current.getText()
        }

        return ''
      },
      getCharacterCount() {
        if (editorRef.current) {
          return editorRef.current.getCharacterCount()
        }

        return ''
      },
    }))

    const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(
        {
          ...eventItem,
          [event.target.name]: event.target.value,
        },
        {
          ...formErrors,
          [event.target.name]: '',
        }
      )
    }

    const onMunicipalityChange = (event: SelectChangeEvent<string>) => {
      onChange(
        {
          ...eventItem,
          [event.target.name]: event.target.value,
          categoryTags: [],
          locationTags: [],
        },
        {
          ...formErrors,
          [event.target.name]: '',
        }
      )
    }

    const onTagChange = (name: string, selectedTags: string | string[]) => {
      onChange(
        {
          ...eventItem,
          [name]: selectedTags,
        },
        {
          ...formErrors,
          [name]: '',
        }
      )
    }

    const onTagDelete = (name: string, index: number) => {
      const current = eventItem[name as keyof typeof eventItem]

      const copy = [...(Array.isArray(current) ? current : [])]

      copy.splice(index, 1)

      onChange(
        {
          ...eventItem,
          [name]: copy,
        },
        {
          ...formErrors,
          [name]: '',
        }
      )
    }

    const onCustomInputChange = <T extends unknown>(name: string, value: T) => {
      onChange(
        {
          ...eventItem,
          [name]: value,
        },
        {
          ...formErrors,
          [name]: '',
        }
      )
    }

    return (
      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          width: '100%',
          '>div, >label': {
            mt: 2,
          },
        }}
      >
        <Typography variant="h1" textAlign="center">
          Pasākums
        </Typography>
        <FormRow label="Nosaukums">
          <TextField
            disabled={disabled}
            value={eventItem.title}
            onChange={onTextFieldChange}
            name="title"
            fullWidth
            helperText={formErrors['title']}
            error={!!formErrors['title']}
          />
        </FormRow>
        <FormRow label="Apraksts">
          <TextEditor maxCharacterCount={MAX_RICH_TEXT_LENGTH} disabled={disabled} value={eventItem.content} ref={editorRef} />
        </FormRow>
        {municipalities && (
          <FormRow label="Pašvaldība">
            <FormControl error={!!formErrors['municipalityId']} fullWidth>
              <Select name="municipalityId" value={eventItem.municipalityId} onChange={onMunicipalityChange}>
                {municipalities.map((municipality, index) => (
                  <MenuItem value={municipality.id} key={index}>
                    {municipality.title}
                  </MenuItem>
                ))}
              </Select>
              {formErrors['municipalityId'] && <FormHelperText>{formErrors['municipalityId']}</FormHelperText>}
            </FormControl>
          </FormRow>
        )}
        <FormRow label="Kategorija">
          <TagSelect
            name="categoryTags"
            selectedTags={eventItem.categoryTags}
            tags={categoryTags}
            disabled={disabled || !eventItem.municipalityId}
            error={formErrors['categoryTags']}
            loading={loadingTags}
            onChange={onTagChange}
            onDelete={onTagDelete}
          />
        </FormRow>
        <FormRow label="Vieta">
          <TagSelect
            name="locationTags"
            selectedTags={eventItem.locationTags}
            tags={locationTags}
            disabled={disabled || !eventItem.municipalityId}
            limit={1}
            error={formErrors['locationTags']}
            loading={loadingTags}
            onChange={onTagChange}
            onDelete={onTagDelete}
          />
        </FormRow>
        <FormRow label="Adrese">
          <AddressAutocomplete
            value={eventItem.address}
            disabled={disabled}
            error={formErrors['address']}
            onChange={(value) => onCustomInputChange('address', value)}
          />
        </FormRow>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={lvLocale}>
          <FormRow label="Norises laiks no">
            <DateTimePicker
              disabled={disabled}
              ampm={false}
              minDateTime={new Date()}
              maxDate={eventItem.endsAt || undefined}
              inputFormat={DEFAULT_DATE_TIME_FORMAT}
              renderInput={(props) => (
                <TextField {...props} fullWidth helperText={formErrors['startsAt']} error={!!formErrors['startsAt']} />
              )}
              value={eventItem.startsAt}
              onChange={(value) => onCustomInputChange('startsAt', value)}
            />
          </FormRow>
          <FormRow label="Norises laiks līdz">
            <DateTimePicker
              disabled={disabled || !eventItem.startsAt}
              ampm={false}
              minDateTime={eventItem.startsAt || undefined}
              inputFormat={DEFAULT_DATE_TIME_FORMAT}
              renderInput={(props) => (
                <TextField {...props} fullWidth helperText={formErrors['endsAt']} error={!!formErrors['endsAt']} />
              )}
              value={eventItem.endsAt}
              onChange={(value) => onCustomInputChange('endsAt', value)}
            />
          </FormRow>
        </LocalizationProvider>
        <FormRow label="Attēli">
          {images.length > 0 && <StaticGallery images={images} canDelete={true} sx={{ mb: 2 }} onDelete={onDeleteImage} />}
          {Capacitor.getPlatform() === 'web' ? (
            <FileUploadButton
              acceptedTypeExtensions={ACCEPTED_FILE_TYPES}
              acceptedTypes={ACCEPTED_UPLOAD_FILE_TYPES}
              disabled={disabled}
              onFileSelect={onWebImage}
            />
          ) : (
            <CameraButton disabled={disabled} onAppImage={onAppImage} />
          )}
        </FormRow>
        <FormActions disabled={disabled} />
      </Box>
    )
  }
)
