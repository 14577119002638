import { useState } from 'react'
import { useUserContext } from '../../store'
import { GRAPHQL_API_FILE_URL } from '../../constants'

export const useUploadGenericFiles = () => {
  const token = useUserContext((state) => state.token)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const uploadGenericFiles = async (files: File[]) => {
    setLoading(true)
    setError(false)

    const request = files.map(async (file) => {
      const formData = new FormData()
      formData.append('file', file)

      return fetch(GRAPHQL_API_FILE_URL, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: token ? `Token ${token}` : '',
        },
      }).then((response) => response.json())
    })

    return Promise.all(request)
      .then((response) => {
        const uploadIds: string[] = response.map((entry) => entry.id)
        setLoading(false)
        return uploadIds
      })
      .catch(() => setError(true))
  }

  return {
    uploadGenericFiles,
    loading,
    error,
  }
}
