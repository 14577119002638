import React from 'react'
import { Button, Typography } from '@mui/material'
import { PageWrap, SafeAreaWrap } from '../../components'
import { useNavigate } from 'react-router-dom'

export const FatalError = () => {
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/', { replace: true })
  }

  return (
    <SafeAreaWrap sx={{ height: '100%' }}>
      <PageWrap sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="h1">Kļūda</Typography>
        <Button onClick={goHome} variant="outlined" sx={{ mt: 2 }}>
          Doties uz sākumu
        </Button>
      </PageWrap>
    </SafeAreaWrap>
  )
}
