import React from 'react'
import { Button, Dialog, DialogActions, DialogContentText, Fade } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { FLEX_DIRECTION_COLUMN } from '../../../style/sx'
import { PageWrap } from '../../PageWrap'
import { SafeAreaWrap } from '../../SafeAreaWrap'
import { TocContent } from './TocContent'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props}></Fade>
})

interface TocDialogProps {
  open: boolean
  inReadMode?: boolean
  onTocChange?: (hasAccepted: boolean) => void
  onClose?: () => void
}

export const TocDialog = ({ open, inReadMode, onTocChange, onClose }: TocDialogProps) => {
  const onTocAccept = () => {
    onTocChange && onTocChange(true)
  }

  const onTocDecline = () => {
    onTocChange && onTocChange(false)
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <SafeAreaWrap sx={{ height: '100%' }}>
        <PageWrap sx={FLEX_DIRECTION_COLUMN}>
          <DialogContentText>
            <TocContent />
          </DialogContentText>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              maxWidth: '320px',
              width: '80%',
              p: 0,
              m: 'auto auto 0',
            }}
          >
            {inReadMode && onClose ? (
              <Button onClick={onClose} variant="outlined">
                Aizvērt
              </Button>
            ) : (
              <>
                <Button onClick={onTocDecline} variant="outlined">
                  Noraidīt
                </Button>
                <Button onClick={onTocAccept} variant="contained">
                  Apstiprināt
                </Button>
              </>
            )}
          </DialogActions>
        </PageWrap>
      </SafeAreaWrap>
    </Dialog>
  )
}
