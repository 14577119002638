import { RouteObject, useRoutes } from 'react-router-dom'
import { SafeAreaTopOverlay, ScrollToTopRestoration, Snackbar } from '../../components'

interface CoreProps {
  routesConfig: RouteObject[]
}

export const Core = ({ routesConfig }: CoreProps) => {
  const routes = useRoutes(routesConfig)

  return (
    <>
      {routes}
      <SafeAreaTopOverlay />
      <ScrollToTopRestoration />
      <Snackbar />
    </>
  )
}
