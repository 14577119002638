import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import { Register, TocDialog, TocDisclaimer, UsernameAuth } from '../../components/Login'
import { SafeAreaWrap } from '../../components'
import { useUserContext } from '../../store'
import { BACKGROUND_CIRCLE, FLEX_DIRECTION_COLUMN } from '../../style/sx'
import { useAuthenticate } from '../../api'
import Fade from '@mui/material/Fade'
import { saveToStorage } from '../../utils'
import { StorageKey } from '../../enums'
import { MobillyTxIcon } from '../../icons'
import { background } from '../../style/sx'

const sx = {
  wrap: {
    width: '100%',
  },
}

enum LoginState {
  UsernameForm,
  Toc,
  Register,
}

interface LoginProps {
  logo?: JSX.Element
  centerLogo?: JSX.Element
}

export const Login = ({ logo, centerLogo }: LoginProps) => {
  const navigate = useNavigate()

  const setToken = useUserContext((state) => state.setToken)
  const setUsername = useUserContext((state) => state.setUsername)
  const { loading, authenticate } = useAuthenticate()
  const matches = useMediaQuery('(min-width:650px)')

  const [openToc, setOpenToc] = useState(false)
  const [tocAccepted, setTocAccepted] = useState(false)
  const [loginState, setLoginState] = useState(LoginState.UsernameForm)

  const onLogin = (username: string, password: string) => {
    if (!tocAccepted) {
      setOpenToc(true)
      return
    }

    authenticate(username, password)
      .then((token) => saveToStorage(StorageKey.Token, token))
      .then((token) => {
        saveToStorage(StorageKey.Username, username).then(() => {
          setUsername(username)
          token && setToken(token)
          navigate('/', { replace: true })
        })
      })
  }

  const onRegister = () => {
    setLoginState(LoginState.Register)
  }

  const onRegisterBack = () => {
    setLoginState(LoginState.UsernameForm)
  }

  const onOpenTerms = () => {
    setOpenToc(true)
  }

  const onTocChange = (acceptedToc: boolean) => {
    setTocAccepted(acceptedToc)
    setOpenToc(false)
  }

  const onTocDisclaimer = (acceptedToc: boolean) => {
    setTocAccepted(acceptedToc)
  }

  const renderLoginState = (state: LoginState) => {
    switch (state) {
      case LoginState.UsernameForm:
        return (
          <UsernameAuth loading={loading} onLogin={onLogin} onRegister={onRegister}>
            <TocDisclaimer
              acceptedToc={tocAccepted}
              disabled={loading}
              onTocDisclaimer={onTocDisclaimer}
              onOpenTerms={onOpenTerms}
            />
          </UsernameAuth>
        )
      case LoginState.Register:
        return <Register onBack={onRegisterBack} />
    }
  }

  return (
    <SafeAreaWrap
      sx={{
        background,
        overflow: 'hidden',
        position: 'relative',
        ':before': BACKGROUND_CIRCLE,
      }}
    >
      {matches ? (
        <Box sx={{ position: 'relative', mt: 5, left: 'calc(50vw - 118px)' }}>
          <MobillyTxIcon height={'77.15px'} width="236px" primaryColor={'white'} secondaryColor={'white'} />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', mt: 5 }}>
          <MobillyTxIcon height={'77.15px'} primaryColor={'white'} secondaryColor={'white'} />
        </Box>
      )}
      <Fade in={true} timeout={1000}>
        <Box
          component="main"
          sx={[
            FLEX_DIRECTION_COLUMN,
            {
              height: '100%',
              pl: 2,
              pr: 2,
            },
          ]}
        >
          <Box sx={[sx.wrap, { mt: 0, ml: 'auto', mr: 'auto', display: 'flex' }]}>{logo}</Box>
          <Box sx={[sx.wrap, { m: 'auto', pb: logo ? 4 : 0 }]}>
            {centerLogo && <Box sx={[sx.wrap, { textAlign: 'center', pb: 4 }]}>{centerLogo}</Box>}
            {renderLoginState(loginState)}
          </Box>
          <TocDialog open={openToc} onTocChange={onTocChange} />
        </Box>
      </Fade>
    </SafeAreaWrap>
  )
}
