import { Box, Typography, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ResultWrap } from '../../components'
import CancelIcon from '@mui/icons-material/Cancel'
import { CheckMarkIcon } from '../../icons'
import { background, BACKGROUND_CIRCLE } from '../../style/sx'

export const ResultSuccess = () => {
  const navigate = useNavigate()
  const onClose = () => {
    navigate('/', { replace: true })
  }

  return (
    <ResultWrap background={background} sx={{ ':before': BACKGROUND_CIRCLE }}>
      <Box sx={{ position: 'relative', mt: '25vh' }}>
        <CheckMarkIcon />
      </Box>
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          textTransform: 'uppercase',
          lineHeight: 1.5,
          position: 'relative',
          color: 'white',
          fontWeight: '500',
        }}
      >
        Piedāvājums izmantots
      </Typography>
      <IconButton
        onClick={onClose}
        sx={{
          mt: 'auto',
          width: '55px',
          height: '55px',
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <CancelIcon sx={{ width: '55px', height: '55px', fill: 'white' }} />
      </IconButton>
    </ResultWrap>
  )
}
