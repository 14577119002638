import { GetOffersQuery } from '../../graphql'
import { OfferListItem } from '../../interfaces'
import { parseIsoString, getTimeInterval, sortByCreatedAt } from '..'

export const mapOfferList = (queryResult: GetOffersQuery): OfferListItem[] => {
  try {
    const offerList = queryResult.loyalty.getOffers.map((item) => {
      return {
        id: item.id,
        createdAt: parseIsoString(item.submittedAt),
        title: item.message.title?.all.lv,
        preview: item.message.preview?.all.lv,
        startsAt: parseIsoString(item.startsAt),
        endsAt: parseIsoString(item.endsAt),
        validityInterval: getTimeInterval(item.startsAt, item.endsAt),
        approvedAt: parseIsoString(item.municipalityApproval[0].approvedAt),
      }
    })

    return sortByCreatedAt(offerList, 'dsc')
  } catch {
    return []
  }
}
