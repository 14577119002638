import { SvgIcon } from '@mui/material'

export const CheckMarkIcon = () => {
  return (
    <SvgIcon viewBox="0 0 59 59" sx={{ width: '55px', height: '55px' }}>
      <path
        d="M57 26.9847V29.5147C56.9927 44.702 44.6727 57.0073 29.4853 57C14.298 56.9927 1.99267 44.6727 2 29.4853C2.00734 14.298 14.3273 1.99267 29.5147 2C33.3647 2 37.1707 2.814 40.6907 4.38334"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M57 7.51465L29.5 35.044L21.25 26.794"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
