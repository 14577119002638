import { useParams, useNavigate } from 'react-router-dom'
import { useDeleteOfferMutation, useGetOfferQuery } from '../../../graphql'
import { Typography, Box, Divider, Button, useMediaQuery } from '@mui/material'
import { Row, StaticGallery, Comments, FullPageLoader, ContentWrap, GroupList } from '../../../components'
import { OfferEntry } from '../../../interfaces'
import { useState, useEffect } from 'react'
import { useConfirmStore, useSnackbarStore } from '../../../store'
import { mapOfferItem } from '../../../utils'
import { GENERIC_ERROR } from '../../../constants'

export const OfferItem = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { loading, error, data } = useGetOfferQuery({ variables: { id: id as string } })
  const [deleteOffer, { loading: deletingOffer }] = useDeleteOfferMutation()

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const confirm = useConfirmStore((state) => state.confirm)

  const matches = useMediaQuery('(min-width:750px)')
  const direction = matches ? 'row' : 'column'

  const onCloseEntry = () => {
    navigate(-1)
  }

  const [offerItem, setOfferItem] = useState<OfferEntry | null>(null)

  useEffect(() => {
    if (data) {
      setOfferItem(mapOfferItem(data))
    }
  }, [data])

  const onDelete = async () => {
    const response = await confirm({
      promptTitle: 'Vai tiešām dzēst šo piedāvājumu?',
      confirmButtonColor: 'error',
      confirmButtonLabel: 'Dzēst',
    })

    if (response && offerItem) {
      deleteOffer({ variables: { id: offerItem.id } }).then(() => {
        navigate('/offers')
      })
    }
  }

  const onEdit = () => {
    if (offerItem) {
      navigate('/offers/edit/' + offerItem.id)
    }
  }

  useEffect(() => {
    if (error) {
      showSnackbar(GENERIC_ERROR, { severity: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <>
      {offerItem ? (
        <Box sx={{ width: '100%', '>div, >form': { mt: 2 } }}>
          <Row label="Nosaukums">
            <Typography variant="h1" sx={{ fontSize: 'inherit' }}>
              {offerItem.title}
            </Typography>
          </Row>
          <Row label="Apraksts">
            <ContentWrap content={offerItem.content} />
          </Row>
          <Row label="Lietotāju grupa">
            <GroupList direction={direction} groups={offerItem.groups} />
          </Row>
          <Row label="Attēli">
            {offerItem.images && offerItem.images.length > 0 ? <StaticGallery images={offerItem.images} /> : <></>}
          </Row>
          <Row label="Izveidošanas datums">
            <Typography>{offerItem.createdAt}</Typography>
          </Row>
          <Row label="Piedāvajums spēkā">
            <Typography>{offerItem.validityInterval}</Typography>
          </Row>
          <Row label="Apstiprināts">
            <Typography>{offerItem.approvedAt}</Typography>
          </Row>
          <Box sx={{ textAlign: 'right', '>button': { ml: 2 } }}>
            {offerItem.approvedAt.length < 1 && (
              <Button onClick={onEdit} variant="contained" color="info">
                Labot
              </Button>
            )}
            <Button variant="contained" color="error" disabled={deletingOffer} onClick={onDelete}>
              Dzēst
            </Button>
            <Button variant="outlined" disabled={deletingOffer} onClick={onCloseEntry}>
              Aizvērt
            </Button>
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Comments comments={offerItem.comments} />
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
