import { INVALID_DATE_TIME_FORMAT, REQUIRED_ERROR_MESSAGE } from '../constants'
import { EventEntry, EventsListItem, StringObject } from '../interfaces'
import {
  getDuration,
  getEndsAt,
  getISOString,
  getTimeInterval,
  mapAttachments,
  mapComments,
  mapTagsForEntry,
  parseDateTime,
  parseIsoString,
  sortByCreatedAt,
} from '.'
import { AttachmentOutputPev, GetEventQuery, GetEventsListQuery, MessageOutputPev, TagOutputPev } from '../graphql'

export const validateEventForm = (
  eventItem: EventEntry,
  edit?: boolean
): [number, StringObject, string | null, string | null] => {
  const errors: StringObject = {}

  const startsAt = getISOString(eventItem.startsAt)
  const endsAt = getISOString(eventItem.endsAt)
  const duration = getDuration(eventItem.startsAt, eventItem.endsAt)

  if (startsAt == null) {
    errors['startsAt'] = INVALID_DATE_TIME_FORMAT
  } else if (!startsAt) {
    errors['startsAt'] = REQUIRED_ERROR_MESSAGE
  }

  if (endsAt == null) {
    errors['endsAt'] = INVALID_DATE_TIME_FORMAT
  } else if (startsAt && !endsAt) {
    errors['endsAt'] = REQUIRED_ERROR_MESSAGE
  }

  if (eventItem.categoryTags.length === 0) {
    errors['categoryTags'] = REQUIRED_ERROR_MESSAGE
  }

  if (eventItem.locationTags.length === 0) {
    errors['locationTags'] = REQUIRED_ERROR_MESSAGE
  }

  if (!eventItem.title) {
    errors['title'] = REQUIRED_ERROR_MESSAGE
  }

  if (!eventItem.address) {
    errors['address'] = REQUIRED_ERROR_MESSAGE
  }

  if (!eventItem.municipalityId && !edit) {
    errors['municipalityId'] = REQUIRED_ERROR_MESSAGE
  }

  return [Object.keys(errors).length, errors, startsAt, duration]
}

export const mapEventsList = (queryResult: GetEventsListQuery): EventsListItem[] => {
  const eventsList = queryResult.publicEvents.getPublicEvents.map((item) => {
    const startsAt = item.schedule.__typename === 'OneOffEventPev' ? item.schedule.startsAt : undefined

    return {
      id: item.id,
      title: item.message.title?.all.lv,
      preview: item.message.preview?.all.lv,
      createdAt: parseIsoString(item.createdAt),
      publishedAt: parseIsoString(item.publishedAt),
      validityInterval: getTimeInterval(startsAt, getEndsAt(startsAt, item.duration)),
    }
  })

  return sortByCreatedAt(eventsList, 'dsc')
}

export const mapEvent = (item: GetEventQuery): [EventEntry | null, StringObject | null] => {
  try {
    const event = item.publicEvents.getPublicEvent

    const startsAt = event.schedule.__typename === 'OneOffEventPev' ? event.schedule.startsAt : undefined

    const [categoryTags, locationTags, tagMap] = mapTagsForEntry(event.message.tags as TagOutputPev[], 'public-events')

    const entry = {
      id: event.id,
      parentId: event.message.id,
      title: event.message.title?.all.lv,
      content: event.message.content?.all.lv,
      address: event.location.address || '',
      startsAt: parseIsoString(startsAt),
      publishedAt: parseIsoString(event.publishedAt),
      canceledAt: parseDateTime(event.canceledAt),
      endsAt: getEndsAt(startsAt, event.duration),
      images: mapAttachments(event.message.attachments as AttachmentOutputPev[]),
      hasRepliesEnabled: event.message.hasRepliesEnabled,
      comments: mapComments(event.message.replies as MessageOutputPev[]),
      municipalityId: event.municipality?.id,
      durationInterval: getTimeInterval(startsAt, getEndsAt(startsAt, event.duration)),
      categoryTags,
      locationTags,
      going: event.going || 0,
      notGoing: event.notGoing || 0,
      maybe: event.maybe || 0,
    }

    return [entry, tagMap]
  } catch {
    return [null, null]
  }
}
