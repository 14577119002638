import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

interface NormalizedNavLinkProps {
  to: string
  children: JSX.Element | JSX.Element[]
  style?: React.CSSProperties
  onClick?: () => void
}

export const NormalizedNavLink = ({ to, children, style, onClick }: NormalizedNavLinkProps) => {
  const linkStyle = useMemo(() => {
    return {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      ...style,
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Link to={to} style={linkStyle} onClick={onClick}>
      {children}
    </Link>
  )
}
