import React, { useRef } from 'react'
import { Box, IconButton, Link, List, ListItem, Typography, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { NormalizedNavLink } from '../NormalizedNavLink'
import { FLEX_DIRECTION_COLUMN } from '../../style/sx'
import { EventIcon, ExitIcon, MobillyTxIcon, OffersIcon, ProfileIcon, SummaryIcon, WarningIcon } from '../../icons'
import { Capacitor } from '@capacitor/core'
import { FooterButton } from '../FooterButton'
import { VIEWPORT_MD } from '../../constants'
import { QrIcon } from '../../icons'

const items = [
  {
    to: '/offers',
    label: 'Piedāvājumi',
    icon: <OffersIcon size={'small'} color={'primary'} />,
  },
  {
    to: '/offers/utilizations',
    label: 'Atskaites',
    icon: <SummaryIcon size={'small'} color={'primary'} />,
  },
  {
    to: '/events',
    label: 'Pasākumi',
    icon: <EventIcon size={'small'} color={'primary'} />,
  },
  {
    to: '/profile',
    label: 'Profils',
    icon: <ProfileIcon size={'small'} color={'primary'} />,
  },
  {
    to: '/logout',
    label: 'Iziet',
    icon: <ExitIcon size={'small'} color={'primary'} />,
  },
]

interface MenuProps {
  onClose: () => void
  onScanner: () => void
}

export const Menu = ({ onClose, onScanner }: MenuProps) => {
  const platform = useRef(Capacitor.getPlatform())
  const matches = useMediaQuery(VIEWPORT_MD)
  const screenMatches = useMediaQuery('(min-width:845px)')
  const variant = matches ? 'h1' : 'h3'
  const width = matches ? '250px' : 'auto'
  const menuWidth = matches ? (screenMatches ? '35vw' : '50vw') : '100vw'

  return (
    <Box
      sx={{
        width: menuWidth,
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        ':before': {
          position: 'absolute',
          width: '1057px',
          height: '1056px',
          left: '51px',
          top: '409px',
          background: 'linear-gradient(150.67deg, #B455A0 11.53%, #8E5BA6 49.44%)',
          borderRadius: '50%',
          content: '""',
        },
      }}
    >
      <Box sx={{ height: '142px', display: 'flex', alignItems: 'center' }}>
        <MobillyTxIcon height="77.16px" width={width} primaryColor={'#B455A0'} secondaryColor={'#414042'} />
        <IconButton
          size="large"
          color="primary"
          onClick={onClose}
          aria-label="Aizvērt"
          sx={{ ml: 'auto', mr: 2, alignSelf: 'center' }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Typography variant={variant} sx={{ width: '85%', ml: 'auto', fontWeight: 500, lineHeight: 1.5 }}>
        Pašvaldību lojalitātes
      </Typography>
      <Box sx={[FLEX_DIRECTION_COLUMN, { justifyContent: 'space-between', height: 'calc(100% - 73px)' }]}>
        <List sx={{ width: '90%', ml: 'auto' }}>
          {items.map((item, index) => (
            <ListItem key={index}>
              <NormalizedNavLink to={item.to} onClick={onClose}>
                <Box component="span" sx={{ display: 'flex', mr: 2 }}>
                  {item.icon}
                </Box>
                <Typography component="span" color="inherit">
                  {item.label}
                </Typography>
              </NormalizedNavLink>
            </ListItem>
          ))}
          <ListItem>
            <Link
              href={`mailto:p2tech@mobillytx.com?subject=Par problēmu "Pašvaldības lojalitātes" lietotnē`}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <WarningIcon size="medium" color={'primary'} />
              <Typography component="span" color="inherit" sx={{ ml: 2 }}>
                Pieteikt lietotnes kļūdu
              </Typography>
            </Link>
          </ListItem>
        </List>
        {platform.current !== 'web' && (
          <Box
            sx={{
              width: '95%',
              position: 'absolute',
              bottom: '5vh',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <FooterButton buttonLabel="Skenēt QR kodu" onClick={onScanner} fullWidth>
              <QrIcon color="accent" size="small" />
            </FooterButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}
