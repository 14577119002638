import { GetMunicipalitiesQuery } from '../graphql'
import { Municipality } from '../interfaces'
import { parseIsoString } from './timeUtils'

export const mapMunicipalities = (query: GetMunicipalitiesQuery): Municipality[] => {
  try {
    return query.municipalities.getMunicipalities.map((item) => ({
      id: item.id,
      number: item.number,
      title: item.displayName.all.lv,
      createdAt: parseIsoString(item.createdAt),
    }))
  } catch {
    return []
  }
}
