import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { DelayedLoader } from '../../../components'
import { FLEX_DIRECTION_COLUMN } from '../../../style/sx'
// import {
//   DEFAULT_DATE_TIME_FORMAT,
// } from '../../../constants'
// import { format } from 'date-fns'

export const UsersList = () => {
  const navigate = useNavigate()

  // const [expanded, setExpanded] = useState<number>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>()

  const dataUsers: never[] = []
  const loadingUsers = false

  const onNewUser = () => {
    navigate('/users/new')
  }

  // const onExpand = (index: number) => {
  //   setExpanded(index !== expanded ? index : undefined);
  // };

  // const onEdit = (id: string) => {
  //   navigate('/users/edit/' + id)
  // };

  // const onDelete = (id: string) => {
  //   setDeleteId(id)
  //   setIsDeleteDialogOpen(true);
  // };

  const onDialogClose = () => {
    setDeleteId(undefined)
    setIsDeleteDialogOpen(false)
  }

  const onDialogConfirm = () => {
    if (deleteId) {
      // deleteOfferMutation({variables: { id: deleteId }}).then(() => {
      //   refetch()
      //   setDeleteId(undefined)
      //   setIsDeleteDialogOpen(false);
      // })
    }
  }

  return (
    <Box component="main" sx={FLEX_DIRECTION_COLUMN}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'fixed',
          zIndex: 2,
          bottom: 'env(safe-area-inset-bottom, 0)',
          left: 'env(safe-area-inset-left, 0)',
          right: 'env(safe-area-inset-right, 0)',
        }}
      >
        <Button sx={{ m: 1, flexGrow: 1 }} variant="contained" onClick={onNewUser}>
          Pievienot jaunu
        </Button>
      </Box>
      {loadingUsers && <DelayedLoader />}
      {!loadingUsers && !dataUsers && <Alert severity="info">Nav neviena ieraksta</Alert>}
      {/* {
        !loadingUsers && dataUsers && <List disablePadding>
        {
          dataUsers.loyalty.getOffers.map((offer: any, index: number) => (
            <ListItemButton onClick={() => onExpand(index)} sx={{padding: 0}}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Divider sx={{ width: '100%' }} />
                <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>{offer.title.all['lv']}</Typography>
                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                  <Typography>{offer.creatorId}</Typography>
                  <Typography>{format(new Date(offer.startsAt), DEFAULT_DATE_TIME_FORMAT)} - {format(new Date(offer.endsAt), DEFAULT_DATE_TIME_FORMAT)}</Typography>
                  <Typography sx={{ mb: 2, mt: 2 }}>{offer.description.all['lv']}</Typography>
                  <Box>
                    <Button
                      sx={{ m: 1}}
                      variant="contained"
                      onClick={() => onEdit(offer.id)}>
                      Labot
                    </Button>
                    <Button
                      sx={{ m: 1}}
                      color="error"
                      variant="contained"
                      onClick={() => onDelete(offer.id)}>
                      Dzēst
                    </Button>
                  </Box>
                </Collapse>
              </Box>
            </ListItemButton>
          ))
        }
        </List>
      } */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Vai tiešām vēlaties dzēst šo lietotāju?</DialogTitle>
        <DialogActions>
          <Button onClick={onDialogClose}>Atcelt</Button>
          <Button onClick={onDialogConfirm} autoFocus>
            Dzēst
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
