import { Box, Typography } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import { BackButton, FooterButton, ResultWrap } from '../../components'
import { useVerifyStore } from '../../store'
import { background, BACKGROUND_CIRCLE } from '../../style/sx'
import { QrIcon } from '../../icons'

export const ResultError = () => {
  const error = useVerifyStore((state) => state.error)
  const navigate = useNavigate()

  if (error == null) {
    return <Navigate to="/" />
  }

  const onScanner = () => {
    navigate('/scanner')
  }

  return (
    <ResultWrap background={background} sx={{ ':before': BACKGROUND_CIRCLE }}>
      <Typography
        variant="h1"
        sx={{
          mt: 'auto',
          mb: 'auto',
          textTransform: 'uppercase',
          position: 'relative',
          lineHeight: 1.5,
          color: 'accent.main',
        }}
      >
        {error}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FooterButton buttonLabel="Skenēt QR kodu" onClick={onScanner}>
          <QrIcon color="accent" size="small" />
        </FooterButton>
        <BackButton color="accent" accentColor="primary" />
      </Box>
    </ResultWrap>
  )
}
