import { Outlet, useRoutes, RouteObject } from 'react-router-dom'
import { OffersHome } from './OffersHome'
import { OfferForm } from './OfferForm'
import { OfferItem } from './OfferItem'
import { UtilizationAggregationsList } from './UtilizationAggregationsList'
import { OfferUtilizationsList } from './OfferUtilizationsList'

const OffersLayout = () => {
  return <Outlet />
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <OffersLayout />,
    children: [
      {
        index: true,
        element: <OffersHome />,
      },
      {
        path: 'new',
        element: <OfferForm />,
      },
      {
        path: 'edit/:id',
        element: <OfferForm />,
      },
      {
        path: 'item/:id',
        element: <OfferItem />,
      },
      {
        path: 'utilizations',
        element: <UtilizationAggregationsList />,
      },
      {
        path: 'utilizations/:id',
        element: <OfferUtilizationsList />,
      },
      {
        path: '*',
        element: <OffersHome />,
      },
    ],
  },
]

export const Offers = () => {
  const element = useRoutes(routes)

  return <>{element}</>
}
