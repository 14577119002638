export const MAX_LENGTH_ERROR = 'Sasniegts maksimālais garums'

export const INVALID_DATE_TIME_FORMAT = 'Nekorekts datuma un/vai laika formāts'

export const REQUIRED_ERROR_MESSAGE = 'Obligāts lauks'

export const GENERIC_ERROR = 'Notikusi kļūda! Lūdzu mēģini vēlreiz'

export const DATE_FROM_BEFORE_TO = 'Datums un laiks no jābūt pirms datums un laiks līdz'

export const DATE_TIME_IN_PAST = 'Datums un laiks nevar būt pagātnē'

export const REQUIRED_MUNICIPALITIES = 'Nav izvēlēta neviena pašvaldība'

// Password validation errors

export const REQUIRED_PASSWORD_LENGTH = 'Parolei jābūt vismaz 10 (desmit) simbolus garai!'

export const PASSWORD_TOO_LONG = 'Parole nedrīkst saturēt vairāk par 64 (sešdesmit četriem) simboliem!'

export const REQUIRED_CAPITAL_LETTER = 'Parolei jāsatur lielais latīņu alfabēta burts!'

export const REQUIRED_LOWER_CASE_LETTER = 'Parolei jāsatur mazais latīņu alfabēta burts!'

export const REQUIRED_NUMBER = 'Parolei jāsatur vismaz viens cipars!'

export const REQUIRED_SPECIAL_CHARACTER = 'Parolei jāsatur vismaz viens īpašais simbols!'

export const PASSWORDS_DO_NOT_MATCH = 'Paroles nesakrīt!'

export const PASSWORD_CONTAINS_USERNAME = 'Parole nevar saturēt lietotājvārdu!'

export const OLD_AND_NEW_PASSWORDS_MATCH = 'Jaunā parole sakrīt ar veco paroli!'

export const HAS_NON_LATIN_CHARACTERS = 'Parole satur neatļautus simbolus'
