import { RangeFilter } from '../../enums'
import { startOfToday, endOfToday, startOfThisWeek, endOfThisWeek, startOfThisMonth, endOfThisMonth } from '../../constants'

export const getDateRange = (range: RangeFilter) => {
  let start: null | Date = null
  let end: null | Date = null

  switch (range) {
    case RangeFilter.TODAY:
      start = startOfToday
      end = endOfToday
      break
    case RangeFilter.CURRENT_WEEK:
      start = startOfThisWeek
      end = endOfThisWeek
      break
    case RangeFilter.CURRENT_MONTH:
      start = startOfThisMonth
      end = endOfThisMonth
      break
    case RangeFilter.NONE:
      start = null
      end = null
      break
  }
  return { start, end }
}

export const getSelectedRange = (startParam: string, endParam: string) => {
  const startDate = new Date(startParam)
  const endDate = new Date(endParam)

  if (
    startDate.getTime() === getDateRange(RangeFilter.TODAY).start?.getTime() &&
    endDate.getTime() === getDateRange(RangeFilter.TODAY).end?.getTime()
  ) {
    return RangeFilter.TODAY
  } else if (
    startDate.getTime() === getDateRange(RangeFilter.CURRENT_WEEK).start?.getTime() &&
    endDate.getTime() === getDateRange(RangeFilter.CURRENT_WEEK).end?.getTime()
  ) {
    return RangeFilter.CURRENT_WEEK
  } else if (
    startDate.getTime() === getDateRange(RangeFilter.CURRENT_MONTH).start?.getTime() &&
    endDate.getTime() === getDateRange(RangeFilter.CURRENT_MONTH).end?.getTime()
  ) {
    return RangeFilter.CURRENT_MONTH
  } else {
    return RangeFilter.NONE
  }
}
