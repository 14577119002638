import { Box, IconButton, Typography, Link, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../store'
import { FLEX_DIRECTION_COLUMN } from '../../style/sx'
import { UserIcon } from '../../icons'
import CancelIcon from '@mui/icons-material/Cancel'
import { VIEWPORT_MD } from '../../constants'
import { FooterButton } from '../../components'

export const Profile = () => {
  const navigate = useNavigate()
  const matches = useMediaQuery(VIEWPORT_MD)
  const username = useUserContext((state) => state.username)

  // const  {data: dataProfile, loading: loadingProfile, error: errorProfile } = useOwnAuthenticationContextQuery()

  const goBack = () => {
    navigate('/', { replace: true })
  }

  // if(loadingProfile){
  //   return <DelayedLoader />
  // }

  const onChangePassword = () => {
    navigate('/change-password')
  }

  return (
    <Box
      component="main"
      sx={[
        FLEX_DIRECTION_COLUMN,
        { alignItems: 'center', mt: '142px', height: 'calc(100vh - 142px - env(safe-area-inset-top, 0))' },
      ]}
    >
      <UserIcon />
      <Typography sx={{ mt: 4 }}>Lietotājvārds</Typography>
      <Typography color="primary" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
        {username}
      </Typography>
      <Link href="#" onClick={onChangePassword} sx={{ mt: 2, color: 'black' }}>
        Nomainīt paroli
      </Link>
      {matches ? (
        <FooterButton onClick={goBack} sx={{ mb: 8 }} color="primary" buttonLabel="Aizvērt" />
      ) : (
        <IconButton
          onClick={goBack}
          sx={{
            mt: 'auto',
            mb: 4,
            width: '55px',
            height: '55px',
          }}
        >
          <CancelIcon color="primary" sx={{ width: '55px', height: '55px' }} />
        </IconButton>
      )}
    </Box>
  )
}
