import { useEffect, useState } from 'react'
import { Autocomplete, TextField, Box, FormGroup } from '@mui/material'
import { getAddressSuggestions } from '../../api'
import { AddressResponse } from '../../interfaces'

interface AddressAutocompleteProps {
  value?: string
  disabled?: boolean
  error?: string
  onChange: (address: string) => void
}

export const AddressAutocomplete = ({ value, disabled, error, onChange }: AddressAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<AddressResponse[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [parsedValue, setParsedValue] = useState<AddressResponse | null>(null)

  useEffect(() => {
    const addressQueryParams = {
      limit: '100',
      search: inputValue,
    }

    async function fetchData() {
      setIsLoading(true)

      getAddressSuggestions(addressQueryParams)
        .then((resp) => setOptions(resp))
        .catch(() => setOptions([]))
        .finally(() => setIsLoading(false))
    }

    const timeoutId = setTimeout(() => {
      fetchData()
    }, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [inputValue])

  useEffect(() => {
    setParsedValue({
      address: value || '',
    })
  }, [value])

  const onAddressSelect = (address: AddressResponse | null) => {
    onChange(address?.address || '')
  }

  return (
    <FormGroup>
      <Autocomplete
        disabled={disabled}
        value={parsedValue}
        onChange={(e, address) => onAddressSelect(address)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        loading={isLoading}
        getOptionLabel={(option) => option.address}
        options={options}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        filterOptions={(x) => x}
        sx={{ flexGrow: 1 }}
        disablePortal={true}
        noOptionsText="Adrese netika atrasta"
        renderOption={(props, jsonResults) => (
          <Box component="li" {...props} key={jsonResults.code}>
            {jsonResults.address}
          </Box>
        )}
        renderInput={(params) => <TextField {...params} sx={{ flexGrow: 1 }} helperText={error} error={!!error} />}
      />
    </FormGroup>
  )
}
