import { AppBar, Button, IconButton, Toolbar, useMediaQuery } from '@mui/material'
import { MenuIcon } from '../../icons'
import { NormalizedNavLink } from '../NormalizedNavLink'
import { MobillyTxIcon } from '../../icons'
import { Capacitor } from '@capacitor/core'
import { useLocation, useNavigate } from 'react-router-dom'

interface HeaderProps {
  onMenuToggle: () => void
}
const includedPaths = new Set(['/result/error', '/result/success/utilized'])

export const SecondHeader = ({ onMenuToggle }: HeaderProps) => {
  const matches = useMediaQuery('(min-width:360px)')
  const width = Capacitor.getPlatform() === 'web' ? '250px' : 'auto'
  const location = useLocation()
  const navigate = useNavigate()
  const menuColor = includedPaths.has(location.pathname) ? 'accent' : 'primary'

  const onLogout = () => {
    navigate('/logout')
  }

  return (
    <AppBar
      color="inherit"
      sx={{
        paddingTop: 'env(safe-area-inset-top, 0)',
        paddingRight: 'env(safe-area-inset-right, 0)',
        paddingLeft: 'env(safe-area-inset-left, 0)',
        background: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ pl: 0, height: '142px', background: 'transparent', backgroundColor: 'transparent', alignItems: 'center' }}>
        <NormalizedNavLink to="/" style={{ width: '4rem' }}>
          {includedPaths.has(location.pathname) ? (
            <MobillyTxIcon height="77.16px" width={width} primaryColor={'#fff'} secondaryColor={'#fff'} />
          ) : (
            <MobillyTxIcon height="77.16px" width={width} primaryColor={'#B455A0'} secondaryColor={'#414042'} />
          )}
        </NormalizedNavLink>
        {location.pathname !== '/' ? (
          <IconButton
            size="large"
            onClick={onMenuToggle}
            aria-label="Atvērt izvēlni"
            sx={{ position: 'absolute', right: 0, pr: '2vw' }}
          >
            <MenuIcon size={matches ? 'medium' : 'small'} color={menuColor} />
          </IconButton>
        ) : (
          <Button
            onClick={onLogout}
            sx={{ position: 'absolute', right: 2, fontWeight: '500', textTransform: 'none', fontSize: '1.2rem', pr: '2vw' }}
          >
            Iziet
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}
