import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DelayedLoader } from '../../components'
import { StorageKey } from '../../enums'
import { useUserContext } from '../../store'
import { FullHeightFlexCenterWrap } from '../../style/styled-components'
import { initStorage, readFromStorage } from '../../utils'

interface InitProps {
  children: JSX.Element | JSX.Element[]
}

export const Init = ({ children }: InitProps) => {
  const navigate = useNavigate()
  const setToken = useUserContext((state) => state.setToken)
  const setUsername = useUserContext((state) => state.setUsername)

  const [doneInit, setDoneInit] = useState(false)

  useEffect(() => {
    initStorage()
      .then(async () => {
        const token = await readFromStorage(StorageKey.Token)
        const username = await readFromStorage(StorageKey.Username)
        token && setToken(token)
        username && setUsername(username)
        setDoneInit(true)
      })
      .catch(() => {
        navigate('/error', { replace: true })
        setDoneInit(true)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {doneInit ? (
        children
      ) : (
        <FullHeightFlexCenterWrap>
          <DelayedLoader />
        </FullHeightFlexCenterWrap>
      )}
    </>
  )
}
