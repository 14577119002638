import { Capacitor } from '@capacitor/core'
import { Button, Dialog, DialogContent, Typography } from '@mui/material'
import { useRef } from 'react'

export const UpdateDialog = () => {
  const platform = useRef(Capacitor.getPlatform())

  const openMarketListing = () => {
    if (platform.current === 'android') {
      window.open('market://details?id=lv.mtx.merchant', '_system')
    }
  }

  return (
    <Dialog open>
      <DialogContent
        sx={{
          mt: 2,
          mb: 2,
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>Mobilajai lietotnei ir pieejama jauna versija.</Typography>
        <Typography sx={{ mt: 3, fontWeight: 500 }}>Lai turpinātu izmantot lietotni, lūdzu veikt atjauninājumu.</Typography>
        {platform.current === 'android' ? (
          <Button sx={{ mt: 3 }} onClick={openMarketListing} fullWidth variant="contained">
            Atjaunināt
          </Button>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}
