import { Chip, Stack } from '@mui/material'
import { StringObject } from '../../interfaces'

interface TagListProps {
  tags: string[]
  tagLabels: StringObject
}

export const TagList = ({ tags, tagLabels }: TagListProps) => {
  return (
    <Stack direction="row" spacing={1}>
      {tags.map((tag) => (
        <Chip key={tag} label={tagLabels[tag] || tag} variant="outlined" />
      ))}
    </Stack>
  )
}
